/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
// import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDButton from "components/MDButton";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Signature pad
import SignatureCanvas from "react-signature-canvas";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// eslint-disable-next-line no-unused-vars
const stylePad = {
  borderColor: "#df2769",
  borderWidth: "1px",
  borderStyle: "dashed",
};

function SignaturePad({ disabled }) {
  const [modal, setModal] = useState(false);
  const sigCanvas = useRef();
  const [signatureItems, setSignatureItems] = useState([]);
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordSignatures = useSelector((state) => state.records.recordSignatures);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const submitSignature = () => {
    sendWebSocket({
      type: "signRecord",
      recordType,
      recordUid,
      imageUrl: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
    setModal(false);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  useEffect(() => {
    if (recordSignatures && recordSignatures.length > 0) {
      setSignatureItems(
        recordSignatures.map((item) => (
          <Grid item xs={3} sm={3}>
            <MDBox my={1}>
              <img
                src={item.imageUrl}
                alt="signature"
                className="signature"
                width={256}
                height={64}
                style={{ objectFit: "scale-down" }}
              />
            </MDBox>

            <MDBox my={1}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                {item.signer}
              </MDTypography>
            </MDBox>
          </Grid>
        ))
      );
    } else {
      setSignatureItems([<Grid item xs={6} sm={6} />]);
    }
  }, [recordSignatures]);

  return recordSignatures ? (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Signaturen</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            {signatureItems}
          </Grid>
        </MDBox>
        <MDBox>
          <MDButton variant="gradient" color="info" onClick={openModal} disabled={disabled}>
            Unterschreiben
          </MDButton>
        </MDBox>
      </MDBox>
      <Modal
        open={modal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox p={3}>
            <MDTypography id="modal-modal-title" variant="h5">
              Diese Datei unterschreiben
            </MDTypography>
            <MDBox mt={1}>
              <Grid container display="flex" justifyContent="center" alignItems="center">
                <Card sx={{ ...stylePad, width: 512, height: 128 }}>
                  <SignatureCanvas
                    penColor="#df2769"
                    canvasProps={{ width: 512, height: 128, className: "sigCanvas" }}
                    ref={sigCanvas}
                  />
                </Card>
              </Grid>
            </MDBox>
            <MDTypography id="modal-modal-description" variant="overline">
              Mit deiner Unterschrift erklärst du dein Einverständnis sowie die Korrektheit deiner
              Angaben.
            </MDTypography>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <MDButton variant="gradient" color="primary" onClick={submitSignature} fullWidth>
                    Bestätigen
                  </MDButton>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MDButton variant="gradient" color="secondary" onClick={clearSignature} fullWidth>
                    Zurücksetzen
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    </Card>
  ) : null;
}

// Setting default values for the props of MDTypography
SignaturePad.defaultProps = {
  disabled: true,
};

// Typechecking props for the MDTypography
SignaturePad.propTypes = {
  disabled: PropTypes.bool,
};

export default SignaturePad;
