/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// React
import { useImperativeHandle, useState, forwardRef, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Redux
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// WebSocket
// eslint-disable-next-line import/no-unresolved
// import { sendWebSocket } from "app/websocket";
// import store from "app/store";
// import { setRecordUid, setRecordType } from "features/records/recordSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// eslint-disable-next-line no-unused-vars
const ImportResourcesModal = forwardRef(({ resources, importResources }, ref) => {
  const [modal, setModal] = useState(false);
  const [resourceEntries, setResourceEntries] = useState(null);
  const [checkedEntries, setCheckedEntries] = useState([]);

  useImperativeHandle(ref, () => ({
    open() {
      setModal(true);
    },
  }));

  const importResourcesHere = () => {
    console.log("checkedEntries:", checkedEntries);
    importResources(checkedEntries.filter((x) => x.checked === true));
    setModal(false);
  };

  const cancelHere = () => {
    setModal(false);
  };

  useEffect(() => {
    let rows = [];
    if (resources) {
      const resourcesChecked = resources.map((x) => ({ ...x, checked: false }));
      setCheckedEntries(resourcesChecked);

      rows = resources.map((item, index) => ({
        resource: item.filename,
        import: (
          <Checkbox
            defaultChecked={false}
            onChange={(event) => {
              const checkedUpdated = [...resourcesChecked];
              checkedUpdated[index].checked = event.target.checked;
              setCheckedEntries(checkedUpdated);
            }}
          />
        ),
      }));
    }

    setResourceEntries({
      columns: [
        { Header: "Ressource", accessor: "resource", width: "80%" },
        { Header: "Import", accessor: "import", width: "20%" },
      ],
      rows: [...rows],
    });
  }, [resources]);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <Modal
      open={modal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox p={3}>
          <MDTypography id="modal-modal-title" variant="h5">
            Verfügbare Ressourcen
          </MDTypography>
          <MDTypography id="modal-modal-description" variant="h6">
            Hier siehst du eine Übersicht aller Elemente, die du hier importieren kannst.
          </MDTypography>
          <MDBox mt={2}>
            <DataTable
              table={resourceEntries || {}}
              entriesPerPage={{ defaultValue: 5 }}
              pagination={{ variant: "gradient", color: "info" }}
              canSearch
            />
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={importResourcesHere}
                >
                  Importieren
                </MDButton>
              </Grid>
              <Grid item xs={6} sm={6}>
                <MDButton variant="gradient" color="secondary" fullWidth onClick={cancelHere}>
                  Abbrechen
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  );
}, []);

export default ImportResourcesModal;
