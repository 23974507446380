/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const recordSlice = createSlice({
  name: "records",
  initialState: {
    records: [
      { type: "medical", uid: "Akte 1" },
      { type: "medical", uid: "Akte 2" },
      { type: "medical", uid: "Akte 3" },
      { type: "medical", uid: "Akte 4" },
    ],
    recordUid: null,
    recordType: null,
    recordAccessors: [{ mail: "test", permission: "sign" }],
    recordEditors: [{ token: "xyz", name: "Testo Steron" }],
    recordContent: null,
    recordSignatures: null,
    recordImagepool: null,
    recordResources: [],
    recordTreatmentpool: null,
  },
  reducers: {
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setRecordUid: (state, action) => {
      state.recordUid = action.payload;
    },
    setRecordType: (state, action) => {
      state.recordType = action.payload;
    },

    setRecordAccessors: (state, action) => {
      state.recordAccessors = action.payload;
    },
    addRecordAccessor: (state, action) => {
      state.recordAccessors.push({
        mail: action.payload.mail,
        permission: action.payload.permission,
      });
    },
    updateRecordAccessor: (state, action) => {
      console.log("updateRecordAccessor", action.payload);

      const index = state.recordAccessors.findIndex((x) => x.mail === action.payload.mail);
      console.log("index", index);
      if (index >= 0) {
        state.recordAccessors[index] = {
          ...state.recordAccessors[index],
          permission: action.payload.permission,
        };
      }
    },
    removeRecordAccessor: (state, action) => {
      const index = state.recordAccessors.findIndex((x) => x.mail === action.payload.mail);
      if (index >= 0) {
        state.recordAccessors.splice(index, 1);
      }
    },

    setRecordEditors: (state, action) => {
      state.recordEditors = action.payload;
    },
    setRecordContent: (state, action) => {
      if (action.payload && !action.payload.mountedContent) {
        // if no mounted content is delivered, replace with already present mounted content
        action.payload.mountedContent = state.recordContent.mountedContent;
      }

      state.recordContent = action.payload;
    },
    setRecordSignatures: (state, action) => {
      state.recordSignatures = action.payload;
    },
    setRecordImagepool: (state, action) => {
      state.recordImagepool = action.payload;
    },
    setRecordResources: (state, action) => {
      state.recordResources = action.payload;
    },
    addRecordResources: (state, action) => {
      state.recordResources = [...state.recordResources, ...action.payload];
    },
    setRecordTreatmentpool: (state, action) => {
      state.recordTreatmentpool = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRecords,
  setRecordUid,
  setRecordType,
  setRecordAccessors,
  addRecordAccessor,
  updateRecordAccessor,
  removeRecordAccessor,
  setRecordEditors,
  setRecordContent,
  setRecordSignatures,
  setRecordImagepool,
  setRecordResources,
  addRecordResources,
  setRecordTreatmentpool,
} = recordSlice.actions;

export default recordSlice.reducer;
