/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// React
import { useImperativeHandle, useState, forwardRef, useEffect, useRef } from "react";

// Redux
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Redux
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// WebSocket
// eslint-disable-next-line import/no-unresolved
// import { sendWebSocket } from "app/websocket";
// import store from "app/store";
// import { setRecordUid, setRecordType } from "features/records/recordSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const options = [
  { label: "Ansehen", value: "view" },
  { label: "Unterschreiben", value: "sign" },
  { label: "Bearbeiten", value: "edit" },
];

// eslint-disable-next-line no-unused-vars
const AccessRightsModal = forwardRef(
  ({ accessors, addAccessor, updateAccessor, removeAccessor, submitChanges, undoChanges }, ref) => {
    const [modal, setModal] = useState(false);
    const [accessorEntries, setAccessorEntries] = useState(null);
    const mailRef = useRef(null);
    const [newPermission, setNewPermission] = useState("view");
    const selectedProfile = useSelector((state) => state.account.selectedProfile);
    const profiles = useSelector((state) => state.account.profiles);

    useImperativeHandle(ref, () => ({
      open() {
        setModal(true);
      },
    }));

    const addAccessorHere = () => {
      console.log("addAccessorHere", mailRef.current.value, newPermission);
      addAccessor(mailRef.current.value, newPermission);
    };

    const updateAccessorHere = (mail, permission) => {
      console.log("updateAccessorHere", mail, permission, updateAccessor);
      updateAccessor(mail, permission);
    };

    const removeAccessorHere = (mail) => {
      console.log("removeAccessorHere", mail);
      removeAccessor(mail);
    };

    const submitChangesHere = () => {
      console.log("submitChangesHere");
      submitChanges();
      setModal(false);
    };

    const undoChangesHere = () => {
      console.log("undoChangesHere");
      undoChanges();
    };

    useEffect(() => {
      console.log("useEffect", accessors);

      let rows = [];
      if (accessors) {
        rows = accessors.map((item) => ({
          mail: item.mail,
          permission: (
            <MDBox>
              <Grid container>
                <Grid item>
                  <Autocomplete
                    value={options.find((x) => x.value === item.permission)}
                    options={options}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                    getOptionLabel={(option) => option.label}
                    disableClearable
                    sx={{ width: "300%" }}
                    onChange={(event, newValue) => updateAccessorHere(item.mail, newValue.value)}
                    disabled={profiles[selectedProfile].mail === item.mail}
                  />
                </Grid>
                <Grid item ml={18}>
                  <MDButton
                    size="small"
                    iconOnly
                    color="secondary"
                    onClick={() => removeAccessorHere(item.mail)}
                  >
                    <Icon
                      sx={{
                        fontWeight: "bold",
                        color: ({ palette: { light } }) => light.main,
                      }}
                    >
                      clear
                    </Icon>
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          ),
        }));
      }

      setAccessorEntries({
        columns: [
          { Header: "Mail", accessor: "mail", width: "50%" },
          { Header: "Berechtigung", accessor: "permission", width: "50%" },
        ],
        rows: [
          ...rows,
          {
            mail: <MDInput variant="standard" inputRef={mailRef} sx={{ width: "150%" }} />,
            permission: (
              <MDBox>
                <Grid container>
                  <Grid item>
                    <Autocomplete
                      defaultValue={options[0]}
                      options={options}
                      renderInput={(params) => <MDInput {...params} variant="standard" />}
                      getOptionLabel={(option) => option.label}
                      disableClearable
                      sx={{ width: "300%" }}
                      onChange={(event, newValue) => {
                        setNewPermission(newValue.value);
                      }}
                    />
                  </Grid>
                  <Grid item ml={18}>
                    <MDButton
                      size="small"
                      iconOnly
                      color="primary"
                      onClick={() => addAccessorHere()}
                    >
                      <Icon
                        sx={{
                          fontWeight: "bold",
                          color: ({ palette: { light } }) => light.main,
                        }}
                      >
                        add
                      </Icon>
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            ),
          },
        ],
      });
    }, [accessors]);

    const closeModal = () => {
      setModal(false);
    };

    return (
      <Modal
        open={modal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox p={3}>
            <MDTypography id="modal-modal-title" variant="h5">
              Zugriffsrechte verwalten
            </MDTypography>
            <MDTypography id="modal-modal-description" variant="h6">
              Hier siehst du eine Übersicht aller Personen, die auf diese Datei zugreifen können.
            </MDTypography>
            <MDBox mt={2}>
              <DataTable
                table={accessorEntries || {}}
                entriesPerPage={{ defaultValue: 5 }}
                pagination={{ variant: "gradient", color: "info" }}
                canSearch
              />
            </MDBox>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    fullWidth
                    onClick={() => submitChangesHere()}
                  >
                    Bestätigen
                  </MDButton>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    fullWidth
                    onClick={() => undoChangesHere()}
                  >
                    Zurücksetzen
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
    );
  },
  []
);

export default AccessRightsModal;
