// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";

// React
import React, { useEffect, useState, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React Components
import MDPagination from "components/MDPagination";

// Redux
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// WebSocket
import { sendWebSocket, getRichSocket } from "app/websocket.ts";
import store from "app/store";
import {
  addDocumentAccessor,
  removeDocumentAccessor,
  setDocumentId,
  updateDocumentAccessor,
} from "features/cloud/cloudSlice";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

// Images
import bgImage1 from "assets/images/bg-home-1.jpg";
import bgImage2 from "assets/images/bg-home-2.jpg";
import bgImage3 from "assets/images/bg-home-3.jpg";
import bgImage4 from "assets/images/bg-home-4.jpg";
import bgImage5 from "assets/images/bg-home-5.jpg";
import brandWhite from "assets/images/logo-cloudstorm.png";
import brandDark from "assets/images/logo-cloudstorm-dark.png";
import { setNavigate } from "features/account/accountSlice";

import AccessRightsModal from "components/nhl/AccessRightsModal";
import StreamingModal from "components/nhl/StreamingModal";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
// import bgImage from "assets/images/bg-pricing.jpg";

// Profile Images
import lsed from "assets/images/lsed.png";
import lspd from "assets/images/lspd.png";
import nhl from "assets/images/nhl_LOGO_web.png";
import mailPng from "assets/images/email.png";

import { useMaterialUIController } from "context";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";
import { setMailDrafts, setMailInbox, setMailOutbox } from "features/mail/mailSlice";

// EditProduct page components
import Members from "layouts/records/editor/components/Members";

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];
const bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = getRichSocket();
// const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
// console.log("socket", socket);
// const connection = new Sharedb.Connection(socket);

let socket: WebSocket;
let con: Sharedb.Connection;
let doc: Sharedb.Doc;

// eslint-disable-next-line no-unused-vars
export function MailOverview() {
  const selectedProfile = useSelector((state) => state.account.selectedProfile);
  const profiles = useSelector((state) => state.account.profiles);
  const mailInbox = useSelector((state) => state.mail.mailInbox);
  const mailOutbox = useSelector((state) => state.mail.mailOutbox);
  const mailDrafts = useSelector((state) => state.mail.mailDrafts);
  const mailEditors = useSelector((state) => state.mail.mailEditors);
  const mailNavigate = useSelector((state) => state.mail.mailNavigate);

  const contentQuill = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const editorOnTextChange = (delta, oldDelta, source) => {
    console.log("editorOnTextChange", delta, oldDelta, source);
    if (source !== "user" && source !== "import") return;
    console.log("submitOp!", source);
    doc.submitOp(delta, {
      source: contentQuill.current.getEditor(),
    });
  };

  const docOnOp = (op, source) => {
    console.log("docOnOp", op, source);
    if (source === contentQuill.current.getEditor()) return;
    contentQuill.current.getEditor().updateContents(op);
  };

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("inbox");

  const [mailId, setMailId] = useState(null);
  const [date, setDate] = useState("");
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState("");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mailItems, setMailItems] = useState([]);

  const closeMail = () => {
    setMailId(null);
    setDate("");
    setFrom([]);
    setTo([]);
    setSubject("");
    contentQuill.current.getEditor().setContents({});
  };

  const openMail = (type, mail) => {
    console.log("openMail", type, mail);
    setTabValue(type);

    // contentQuill.current.getEditor().off("text-change", editorOnTextChange);
    if (doc) {
      // contentQuill.current.getEditor().removeAllListeners();
      doc.off("op", docOnOp);
      // doc.removeAllListeners();
      doc.unsubscribe();
      doc.destroy();

      console.log("doc destroyed");
    }
    if (con) {
      // con.close();
    }

    if (type === "inbox" || type === "outbox") {
      setMailId(mail.id);
      setDate(new Date(mail.ts).toLocaleString());
      setFrom(mail.from);
      setTo(mail.to);
      setSubject(mail.subject);
      console.log(mail.content);
      contentQuill.current.getEditor().setContents(mail.content);
    }
    if (type === "drafts") {
      setMailId(mail.id);
      setDate(new Date(mail.ts).toLocaleString());
      setFrom(mail.from);
      setTo(mail.to);
      setSubject(mail.subject);

      console.log("load draft");
      if (mail.id === mailId) {
        console.log("Mail already open... abort");
        return;
      }

      if (!socket) {
        socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
        con = new Sharedb.Connection(socket);
      }

      // Get richtext/quill data

      doc = con.get("documents", `mail.drafts.${profiles[selectedProfile].mail}.${mail.id}`);
      console.log("doc created");

      doc.on("error", (err) => {
        console.error(err);
      });

      doc.subscribe((err) => {
        console.log("doc subscribed");

        if (err) {
          console.error(`error:${err}`);
          return;
        }
        if (!doc.type) {
          console.error(
            `Document doesn't exist yet: mail.drafts.${profiles[selectedProfile].mail}.${mail.id}`
          );
          return;
        }

        console.log("data", doc.data);

        contentQuill.current.getEditor().setContents(doc.data);

        doc.on("op", docOnOp);
      });
    }
  };

  useEffect(() => {
    if (mailNavigate) {
      let mails = [];

      if (mailNavigate.mailBox === "inbox") {
        mails = mailInbox;
      }
      if (mailNavigate.mailBox === "outbox") {
        mails = mailOutbox;
      }
      if (mailNavigate.mailBox === "drafts") {
        mails = mailDrafts;
      }

      // setTabValue(mailNavigate.mailBox);

      if (mails.length === 0) {
        closeMail();
        return;
      }

      const mailIndex = mailNavigate.mailId
        ? mails.findIndex((mail) => mail.id === mailNavigate.mailId)
        : 0;
      console.log(
        "mailIndex:",
        mailIndex,
        mailInbox,
        mailOutbox,
        mailDrafts,
        mails,
        mailNavigate.mailId,
        mailNavigate.mailBox
      );
      console.log("tabValue:", tabValue);
      if (mailIndex >= 0) {
        openMail(mailNavigate.mailBox, mails[mailIndex]);
      }
    }
  }, [mailNavigate]);

  useEffect(() => {
    let mails = [];

    if (tabValue === "inbox") {
      mails = mailInbox;
    }
    if (tabValue === "outbox") {
      mails = mailOutbox;
    }
    if (tabValue === "drafts") {
      mails = mailDrafts;
    }

    if (mails) {
      setMailItems(
        mails.map((mail) => {
          if (mail.id === mailId) {
            console.log("opennn");
            openMail(tabValue, mail);
          }

          return (
            <>
              <MDBox key={mail.id} component="li" display="flex" alignItems="center">
                <MDBox mr={2}>
                  <MDAvatar
                    src={mailPng}
                    alt="Email icons created by Uniconlabs - Flaticon"
                    shadow="md"
                    href="https://www.flaticon.com/free-icons/email"
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <MDTypography variant="button" fontWeight="medium">
                    {mail.subject}
                  </MDTypography>
                  <MDTypography variant="caption" color="text">
                    {tabValue === "inbox" ? mail.from[0] : mail.to[0]}
                  </MDTypography>
                </MDBox>
                <MDBox ml="auto">
                  {/* <MDTypography component={MDButton} variant="body2" color="info">
      <Tooltip title="Antworten" placement="top">
        <Icon>edit</Icon>
      </Tooltip>
    </MDTypography> */}
                  <MDButton variant="text" color="info" onClick={() => openMail(tabValue, mail)}>
                    Ansehen
                  </MDButton>
                </MDBox>
              </MDBox>
              <Divider orientation="horizontal" />
            </>
          );
        })
      );

      if (mails.length > 0) {
        // openMail(tabValue, mails[0]);
      } else {
        setMailId(null);
        setDate("");
        setFrom([]);
        setTo([]);
        setSubject("");
        contentQuill.current.getEditor().setContents({});
      }
    } else {
      setMailItems([]);
    }
  }, [tabValue, mailInbox, mailOutbox, mailDrafts]);

  /* useEffect(() => {
    let mails = [];

    if (tabValue === "inbox") {
      mails = mailInbox;
    }
    if (tabValue === "outbox") {
      mails = mailOutbox;
    }
    if (tabValue === "drafts") {
      mails = mailDrafts;
    }

    if (mails.length > 0) {
      openMail(tabValue, mails[0]);
    } else {
      setMailId(null);
      setDate("");
      setFrom([]);
      setTo([]);
      setSubject("");
      contentQuill.current.getEditor().setContents({});
    }
  }, [tabValue]); */

  useEffect(() => {
    console.log("useEffect: contentQuill", contentQuill);
    if (contentQuill != null) {
      contentQuill.current.getEditor().off("text-change", editorOnTextChange);
      contentQuill.current.getEditor().on("text-change", editorOnTextChange);
    }
  }, [contentQuill]);

  useEffect(() => {
    let mails = [];

    if (tabValue === "inbox") {
      mails = mailInbox;
    }
    if (tabValue === "outbox") {
      mails = mailOutbox;
    }
    if (tabValue === "drafts") {
      mails = mailDrafts;
    }

    if (mails.length > 0) {
      openMail(tabValue, mails[0]);
    } else {
      setMailId(null);
      setDate("");
      setFrom([]);
      setTo([]);
      setSubject("");
      contentQuill.current.getEditor().setContents({});
    }

    return () => {
      if (contentQuill != null && contentQuill.current != null) {
        contentQuill.current.getEditor().off("text-change", editorOnTextChange);
      }
    };
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const updateTo = (newValue) => {
    sendWebSocket({
      type: "updateMailDraft",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
      to: newValue,
    });
  };

  const updateSubject = (newValue) => {
    sendWebSocket({
      type: "updateMailDraft",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
      subject: newValue,
    });
  };

  useEffect(() => {
    store.dispatch(setMailDrafts([]));
    store.dispatch(setMailInbox([]));
    store.dispatch(setMailOutbox([]));

    sendWebSocket({
      type: "openMail",
      address: profiles[selectedProfile].mail,
    });

    // checkCanSign(selectedProfile, profiles, recordAccessors);

    return () => {
      sendWebSocket({
        type: "closeMail",
        address: profiles[selectedProfile].mail,
      });
    };
  }, [selectedProfile]);

  const createDraft = () => {
    sendWebSocket({
      type: "createMailDraft",
      address: profiles[selectedProfile].mail,
    });
  };

  const sendDraft = () => {
    sendWebSocket({
      type: "sendMailDraft",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
    });
  };

  const discardDraft = () => {
    sendWebSocket({
      type: "discardMailDraft",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
    });
  };

  const deleteInbox = () => {
    sendWebSocket({
      type: "deleteMailInbox",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
    });
  };

  const deleteOutbox = () => {
    sendWebSocket({
      type: "deleteMailOutbox",
      address: profiles[selectedProfile].mail,
      draftId: mailId,
    });
  };

  return (
    <>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "fill",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {/* <Grid item>
  <MDAvatar src="" alt="profile-image" size="xl" shadow="sm" />
</Grid> */}
            <Grid item xs={4} lg={4}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Mail
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Hier findest du deine persönlichen und beruflichen Mailverläufe.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={8} lg={8}>
              <Grid container display="flex" justifyContent="flex-end" alignItems="flex-end">
                <MDBox>
                  <Members members={mailEditors} />
                </MDBox>
                <MDBox>
                  <AppBar position="static">
                    <Tabs
                      orientation={tabsOrientation}
                      value={tabValue}
                      onChange={handleSetTabValue}
                    >
                      <Tab
                        label="Inbox"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            home
                          </Icon>
                        }
                        value="inbox"
                      />
                      <Tab
                        label="Gesendet"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            email
                          </Icon>
                        }
                        value="outbox"
                      />
                      <Tab
                        label="Entwürfe"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            settings
                          </Icon>
                        }
                        value="drafts"
                      />
                    </Tabs>
                  </AppBar>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={3} lg={3}>
          <Card>
            <MDBox p={3}>
              <MDBox component="ul" display="flex" flexDirection="column" py={1} m={0}>
                {mailItems}
              </MDBox>
              {/* <MDBox mt={4}>
                <MDPagination>
                  <MDPagination item>
                    <Icon>keyboard_arrow_left</Icon>
                  </MDPagination>
                  <MDPagination item active>
                    1
                  </MDPagination>
                  <MDPagination item>2</MDPagination>
                  <MDPagination item>3</MDPagination>
                  <MDPagination item>
                    <Icon>keyboard_arrow_right</Icon>
                  </MDPagination>
                </MDPagination>
                      </MDBox> */}
              <MDBox mt={4}>
                <MDButton variant="gradient" color="info" fullWidth onClick={() => createDraft()}>
                  Neue Mail
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={9} lg={9}>
          <Card>
            <MDBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                  <MDBox mt={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDBox my={1} display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Datum
                          </MDTypography>
                        </MDBox>
                        <MDInput type="text" variant="standard" fullWidth value={date} disabled />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDBox mt={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDBox my={1} display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Von
                          </MDTypography>
                        </MDBox>
                        <Autocomplete
                          multiple
                          freeSolo
                          autoSelect
                          value={from}
                          onChange={(event, newValue) => {
                            // updateField("injuries", newValue);
                          }}
                          options={[]}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" disabled />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <MDBox mt={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDBox my={1} display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            An
                          </MDTypography>
                        </MDBox>
                        <Autocomplete
                          multiple
                          freeSolo
                          autoSelect
                          value={to}
                          onChange={(event, newValue) => {
                            updateTo(newValue);
                          }}
                          options={[]}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              disabled={tabValue !== "drafts"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MDBox my={1} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Betreff
                      </MDTypography>
                    </MDBox>
                    <MDInput
                      type="text"
                      variant="standard"
                      fullWidth
                      value={subject}
                      disabled={tabValue !== "drafts"}
                      onChange={(event) => {
                        updateSubject(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Inhalt&nbsp;&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDEditorRoot ownerState={{ darkMode }}>
                      <ReactQuill
                        theme="snow"
                        ref={contentQuill}
                        readOnly={
                          tabValue !== "drafts" || (tabValue === "drafts" && mailId === null)
                        }
                      />
                    </MDEditorRoot>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={4}>
                <Grid container spacing={3}>
                  {tabValue === "drafts" ? (
                    <Grid item xs={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={() => sendDraft()}
                      >
                        Email absenden
                      </MDButton>
                    </Grid>
                  ) : null}

                  {tabValue === "drafts" ? (
                    <Grid item xs={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        fullWidth
                        onClick={() => discardDraft()}
                      >
                        Email verwerfen
                      </MDButton>
                    </Grid>
                  ) : null}

                  {tabValue === "inbox" ? (
                    <Grid item xs={12} sm={12}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        fullWidth
                        onClick={() => deleteInbox()}
                      >
                        Inbox-Email löschen
                      </MDButton>
                    </Grid>
                  ) : null}

                  {tabValue === "outbox" ? (
                    <Grid item xs={12} sm={12}>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        fullWidth
                        onClick={() => deleteOutbox()}
                      >
                        Outbox-Email löschen
                      </MDButton>
                    </Grid>
                  ) : null}
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default MailOverview;
