/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket, getRichSocket } from "app/websocket";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Richtext editor
import ReactQuill from "react-quill";
import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
// const connection = new Sharedb.Connection(socket);

function Suspect() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);
  const informationQuill = useRef(null);
  const [suspectIdFocused, setSuspectIdFocused] = useState(false);
  const [suspectId, setSuspectId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");

  const updateSuspectIdField = () => {
    const newContent = {};
    newContent.suspectId = suspectId;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    if (recordContent) {
      if (recordContent.suspectId) {
        setSuspectId(recordContent.suspectId);
      }

      if (recordContent.mountedContent) {
        const citizen = recordContent.mountedContent.find((x) => x.type === "citizen");
        if (citizen) {
          if (citizen.citizenFirstName) {
            setFirstName(citizen.citizenFirstName);
          }
          if (citizen.citizenLastName) {
            setLastName(citizen.citizenLastName);
          }
          if (citizen.citizenBirthday) {
            setBirthday(citizen.citizenBirthday);
          }
          if (citizen.citizenInformation) {
            informationQuill.current.getEditor().setContents(citizen.citizenInformation);
          }
        } else {
          setFirstName("");
          setLastName("");
          setBirthday("");
          informationQuill.current.getEditor().setContents({ insert: "" });
        }
      } else {
        setFirstName("");
        setLastName("");
        setBirthday("");
        informationQuill.current.getEditor().setContents({ insert: "" });
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Betroffene Person</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="ID"
                  fullWidth
                  value={suspectId}
                  onChange={(event) => {
                    setSuspectId(event.target.value);
                  }}
                  onFocus={() => {
                    setSuspectIdFocused(true);
                  }}
                  onBlur={() => {
                    if (suspectIdFocused) {
                      updateSuspectIdField();
                    }
                    setSuspectIdFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDBox mb={2}>
                <MDInput variant="standard" label="Vorname" fullWidth value={firstName} disabled />
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDBox mb={2}>
                <MDInput variant="standard" label="Name" fullWidth value={lastName} disabled />
              </MDBox>
            </Grid>
            <Grid item xs={2} sm={2}>
              <MDBox mb={2}>
                <MDInput
                  variant="standard"
                  label="Geburtstag"
                  fullWidth
                  value={birthday}
                  disabled
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Allgemeine Informationen&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (Auffälligkeiten, Informantentätigkeit)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={informationQuill} readOnly />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Suspect;
