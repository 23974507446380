/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Richtext editor
import ReactQuill from "react-quill";
import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

function Master() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);
  const [citizenIdFocused, setCitizenIdFocused] = useState(false);
  const [firstNameFocused, setFirstNameFocused] = useState(false);
  const [lastNameFocused, setLastNameFocused] = useState(false);
  const [birthdayFocused, setBirthdayFocused] = useState(false);
  const [citizenId, setCitizenId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    if (recordContent) {
      if (recordContent.citizenId) {
        setCitizenId(recordContent.citizenId);
      }
      if (recordContent.citizenFirstName) {
        setFirstName(recordContent.citizenFirstName);
      }
      if (recordContent.citizenLastName) {
        setLastName(recordContent.citizenLastName);
      }
      if (recordContent.citizenBirthday) {
        setBirthday(recordContent.citizenBirthday);
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Stammdaten</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="ID"
                  fullWidth
                  value={citizenId}
                  onChange={(event) => {
                    setCitizenId(event.target.value);
                  }}
                  onFocus={() => {
                    setCitizenIdFocused(true);
                  }}
                  onBlur={() => {
                    if (citizenIdFocused) {
                      updateField("citizenId", citizenId);
                    }
                    setCitizenIdFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="Vorname"
                  fullWidth
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  onFocus={() => {
                    setFirstNameFocused(true);
                  }}
                  onBlur={() => {
                    if (firstNameFocused) {
                      updateField("citizenFirstName", firstName);
                    }
                    setFirstNameFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="Name"
                  fullWidth
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  onFocus={() => {
                    setLastNameFocused(true);
                  }}
                  onBlur={() => {
                    if (lastNameFocused) {
                      updateField("citizenLastName", lastName);
                    }
                    setLastNameFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="Geburtstag"
                  fullWidth
                  value={birthday}
                  onChange={(event) => {
                    setBirthday(event.target.value);
                  }}
                  onFocus={() => {
                    setBirthdayFocused(true);
                  }}
                  onBlur={() => {
                    if (birthdayFocused) {
                      updateField("citizenBirthday", birthday);
                    }
                    setBirthdayFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Master;
