// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Components
import React from "react";
import { DocumentExplorer } from "./components/DocumentExplorer/index";
// Data

// Images

function CloudHome() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <DocumentExplorer />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CloudHome;
