/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
// const connection = new Sharedb.Connection(socket);

function Incidents() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const anomaliesQuill = useRef(null);
  const incidentsQuill = useRef(null);
  const commentsQuill = useRef(null);

  let socket;
  let con;
  let anomaliesDoc;
  const anomaliesEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    anomaliesDoc.submitOp(delta, {
      source: anomaliesQuill.current.getEditor(),
    });
  };
  const anomaliesDocOnOp = (op, source) => {
    if (source === anomaliesQuill.current.getEditor()) return;
    anomaliesQuill.current.getEditor().updateContents(op);
  };
  let incidentsDoc;
  const incidentsEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    incidentsDoc.submitOp(delta, {
      source: incidentsQuill.current.getEditor(),
    });
  };
  const incidentsDocOnOp = (op, source) => {
    if (source === incidentsQuill.current.getEditor()) return;
    incidentsQuill.current.getEditor().updateContents(op);
  };
  let commentsDoc;
  const commentsEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    commentsDoc.submitOp(delta, {
      source: commentsQuill.current.getEditor(),
    });
  };
  const commentsDocOnOp = (op, source) => {
    if (source === commentsQuill.current.getEditor()) return;
    commentsQuill.current.getEditor().updateContents(op);
  };

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    console.log("useEffect on recordUid change");

    // Get richtext/quill data
    if (anomaliesDoc) {
      anomaliesQuill.current.getEditor().off("text-change", anomaliesEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      anomaliesDoc.off("op", anomaliesDocOnOp);
      // doc.removeAllListeners();
      anomaliesDoc.unsubscribe();
      anomaliesDoc.destroy();
    }
    if (incidentsDoc) {
      incidentsQuill.current.getEditor().off("text-change", incidentsEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      incidentsDoc.off("op", incidentsDocOnOp);
      // doc.removeAllListeners();
      incidentsDoc.unsubscribe();
      incidentsDoc.destroy();
    }
    if (commentsDoc) {
      commentsQuill.current.getEditor().off("text-change", commentsEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      commentsDoc.off("op", commentsDocOnOp);
      // doc.removeAllListeners();
      commentsDoc.unsubscribe();
      commentsDoc.destroy();
    }
    if (con) {
      // con.close();
    }
    if (socket) {
      socket.close();
    }

    socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
    con = new Sharedb.Connection(socket);

    // Get richtext/quill data for anomalies doc

    anomaliesDoc = con.get("documents", `record.corruption.${recordUid}.anomalies`);

    anomaliesDoc.on("error", (err) => {
      console.error(err);
    });

    anomaliesDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!anomaliesDoc.type) {
        console.error(`Document doesn't exist yet: record.corruption.${recordUid}.anomalies`);
        return;
      }

      console.log("data", anomaliesDoc.data);

      anomaliesQuill.current.getEditor().setContents(anomaliesDoc.data);

      anomaliesQuill.current.getEditor().on("text-change", anomaliesEditorOnTextChange);

      anomaliesDoc.on("op", anomaliesDocOnOp);
    });

    // Get richtext/quill data for incidents doc

    incidentsDoc = con.get("documents", `record.corruption.${recordUid}.incidents`);

    incidentsDoc.on("error", (err) => {
      console.error(err);
    });

    incidentsDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!incidentsDoc.type) {
        console.error(`Document doesn't exist yet: record.corruption.${recordUid}.incidents`);
        return;
      }

      console.log("data", incidentsDoc.data);

      incidentsQuill.current.getEditor().setContents(incidentsDoc.data);

      incidentsQuill.current.getEditor().on("text-change", incidentsEditorOnTextChange);

      incidentsDoc.on("op", incidentsDocOnOp);
    });

    // Get richtext/quill data for comments doc

    commentsDoc = con.get("documents", `record.corruption.${recordUid}.comments`);

    commentsDoc.on("error", (err) => {
      console.error(err);
    });

    commentsDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!commentsDoc.type) {
        console.error(`Document doesn't exist yet: record.corruption.${recordUid}.comments`);
        return;
      }

      console.log("data", commentsDoc.data);

      commentsQuill.current.getEditor().setContents(commentsDoc.data);

      commentsQuill.current.getEditor().on("text-change", commentsEditorOnTextChange);

      commentsDoc.on("op", commentsDocOnOp);
    });

    return () => {
      // console.log("Closing connection");
      // connection.close();
    };
  }, [recordUid]);

  useEffect(() => {
    if (recordContent) {
      /* if (recordContent.infringements) {
        setIifringements(recordContent.infringements);
      }
      if (recordContent.witnesses) {
        setWitnesses(recordContent.witnesses);
      }
      if (recordContent.penalties) {
        setPenalties(recordContent.penalties);
      } */
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Risikobewertung</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Auffälligkeiten&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={anomaliesQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Vorfälle&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={incidentsQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Kommentare&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={commentsQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Incidents;
