/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
// import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
// import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import ImportResourcesModal from "components/nhl/ImportResourcesModal";
import Image from "../../Image";

function Evidences() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordImagepool = useSelector((state) => state.records.recordImagepool);
  const recordContent = useSelector((state) => state.records.recordContent);
  const recordResources = useSelector((state) => state.records.recordResources);
  const [imageItems, setImageItems] = useState([]);
  const importModalRef = useRef();

  const openImport = () => {
    sendWebSocket({
      type: "getRecordImagepool",
      recordType,
      recordUid,
    });

    importModalRef.current.open();
  };

  const importResourcesHere = (resources) => {
    // eslint-disable-next-line no-console
    console.log("importResourcesHere (2)", resources);

    const newContent = {};
    if (!recordContent.evidences) {
      newContent.evidences = [];
    } else {
      newContent.evidences = [...recordContent.evidences];
    }
    // eslint-disable-next-line no-underscore-dangle
    newContent.evidences = [...newContent.evidences, ...resources.map((x) => x._id)];

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  const deleteResourceHere = (id) => {
    // eslint-disable-next-line no-console
    console.log("deleteResourceHere", id);

    const newContent = {};
    if (!recordContent.evidences) {
      newContent.evidences = [];
    } else {
      newContent.evidences = [...recordContent.evidences];
      // eslint-disable-next-line no-underscore-dangle
      const foundIndex = newContent.evidences.findIndex((x) => x === id);
      newContent.evidences.splice(foundIndex, 1);
    }

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    if (recordContent && recordContent.evidences && recordContent.evidences.length > 0) {
      setImageItems(
        recordContent.evidences.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const resource = recordResources.find((x) => x._id === item);

          if (!resource) {
            sendWebSocket({
              type: "getRecordResource",
              id: item,
            });
          }

          const content = resource ? resource.content : "";
          const title = resource ? resource.filename : "Loading";

          return (
            <Grid item xs={12} lg={12}>
              <Image src={content} title={title} deleteCallback={() => deleteResourceHere(item)} />
            </Grid>
          );
        })
      );
    } else {
      setImageItems([<Grid item xs={6} sm={6} />]);
    }
  }, [recordContent, recordResources]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography mb={5} variant="h5">
          Beweise
        </MDTypography>
        <Grid container spacing={5}>
          {imageItems}
        </Grid>
        <MDBox mt={2}>
          <MDButton variant="gradient" color="secondary" onClick={() => openImport()} fullWidth>
            Neue Beweise hinzufügen
          </MDButton>
          <ImportResourcesModal
            ref={importModalRef}
            resources={recordImagepool}
            importResources={importResourcesHere}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Evidences;
