/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import store from "app/store";
import { Provider } from "react-redux";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import Authenticator from "context/authenticator";
import App from "./App";
// import { navigationRef } from "./context/navigation";

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <Authenticator>
            <App />
          </Authenticator>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
