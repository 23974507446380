/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";

// React
import { useEffect, useState, useRef } from "react";

// Material Dashboard 2 PRO React examples
// import DataTable from "examples/Tables/DataTable";

// Redux
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";
import store from "app/store";
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import {
  setRecordUid,
  setRecordType,
  setRecordAccessors,
  addRecordAccessor,
  updateRecordAccessor,
  removeRecordAccessor,
} from "features/records/recordSlice";
import AccessRightsModal from "components/nhl/AccessRightsModal";

// Images
import bgImage1 from "assets/images/bg-home-1.jpg";
import bgImage2 from "assets/images/bg-home-2.jpg";
import bgImage3 from "assets/images/bg-home-3.jpg";
import bgImage4 from "assets/images/bg-home-4.jpg";
import bgImage5 from "assets/images/bg-home-5.jpg";
import brandWhite from "assets/images/logo-cloudstorm.png";
import brandDark from "assets/images/logo-cloudstorm-dark.png";
import { setNavigate } from "features/account/accountSlice";
// import bgImage from "assets/images/bg-pricing.jpg";

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];
const bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

const RECORD_ACRONYMS = [
  { full: "medical", short: "MDC" },
  { full: "patient", short: "PNT" },
  { full: "criminal", short: "CRM" },
  { full: "fine", short: "FNE" },
  { full: "warning", short: "WRN" },
  { full: "corruption", short: "CPN" },
  { full: "contract", short: "CTT" },
  { full: "citizen", short: "CZN" },
  { full: "autopsy", short: "OHP" },
];

// eslint-disable-next-line no-unused-vars
export function RecordsExplorer(props) {
  const records = useSelector((state) => state.records.records);
  const recordAccessors = useSelector((state) => state.records.recordAccessors);
  const selectedProfile = useSelector((state) => state.account.selectedProfile);
  const navigateTo = useSelector((state) => state.account.navigate);
  const [recordItems, setRecordItems] = useState(null);
  // const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const accessModalRef = useRef();
  const [accessType, setAccessType] = useState(null);
  const [accessUid, setAccessUid] = useState(null);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const [recordTypes, setRecordTypes] = useState([]);
  const [recordFilter, setRecordFilter] = useState([]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const openRecord = (recordType, recordUid) => {
    console.log("Open Record", recordUid, recordType);
    store.dispatch(setRecordUid(recordUid));
    store.dispatch(setRecordType(recordType));
    navigate({ pathname: "/records/editor", search: window.location.search });
  };

  const createRecord = () => {
    sendWebSocket({
      type: "createRecord",
      recordType: recordTypes[0].full,
    });
  };

  const deleteRecord = (recordType, recordUid) => {
    console.log("Delete Record", recordUid, recordType);
    sendWebSocket({
      type: "deleteRecord",
      recordType,
      recordUid,
    });
  };

  const openAccess = (recordType, recordUid) => {
    // store.dispatch(setRecordAccessors(null));
    sendWebSocket({
      type: "getRecordAccessors",
      recordType,
      recordUid,
    });

    setAccessType(recordType);
    setAccessUid(recordUid);

    accessModalRef.current.open();
  };

  const addRecordAccessorHere = (mail, permission) => {
    console.log("addRecordAccessorHere(2)", mail, permission);
    store.dispatch(addRecordAccessor({ mail, permission }));
  };

  const updateRecordAccessorHere = (mail, permission) => {
    console.log("updateRecordAccessorHere (2)", mail, permission);
    store.dispatch(updateRecordAccessor({ mail, permission }));
  };

  const removeRecordAccessorHere = (mail) => {
    console.log("removeRecordAccessorHere (2)", mail);
    store.dispatch(removeRecordAccessor({ mail }));
  };

  const submitRecordAccessorChanges = () => {
    console.log("submitRecordAccessorChanges (2)");
    sendWebSocket({
      type: "setRecordAccessors",
      recordType: accessType,
      recordUid: accessUid,
      recordAccessors,
    });
  };

  const undoRecordAccessorChanges = () => {
    console.log("undoRecordAccessorChanges (2)");
    sendWebSocket({
      type: "getRecordAccessors",
      recordType: accessType,
      recordUid: accessUid,
    });
  };

  // const fileItems = ["Item 6", "Item 7", "Item 8", "Item 9", "Item 10"];
  useEffect(() => {
    sendWebSocket({
      type: "getRecords",
    });
  }, [selectedProfile]);

  useEffect(() => {
    const target = navigateTo;
    store.dispatch(setNavigate(null));
    navigate({ pathname: target, search: window.location.search });
  }, [navigateTo]);

  const getIcon = (recordType) => {
    switch (recordType) {
      case "medical":
        return { color: "primary", component: "emergency" };
      case "patient":
        return { color: "primary", component: "bloodtype" };
      case "criminal":
        return { color: "dark", component: "crisis_alert" };
      case "fine":
        return { color: "dark", component: "request_quote" };
      case "warning":
        return { color: "dark", component: "record_voice_over" };
      case "corruption":
        return { color: "dark", component: "person_search" };
      case "contract":
        return { color: "warning", component: "history_edu" };
      case "citizen":
        return { color: "info", component: "account_box" };
      case "autopsy":
        return { color: "primary", component: "hourglass_disabled" };
      default:
        return { color: "info", component: "description" };
    }
  };

  useEffect(() => {
    if (records) {
      const filtered = records.filter(
        (item) =>
          recordTypes === null ||
          recordTypes.length === 0 ||
          recordTypes.findIndex((x) => x.full === item.type) >= 0
      );

      setRecordItems(
        filtered.map((item) => (
          <Grid item xs={2} sm={3} key={item.uid}>
            <DirectoryCard
              title={{ text: item.uid }}
              icon={getIcon(item.type)}
              direction="left"
              contextItems={[
                {
                  name: "Öffnen",
                  icon: "file_open",
                  action: () => openRecord(item.type, item.uid),
                },
                {
                  name: "Zugriffsrechte",
                  icon: "key",
                  action: () => openAccess(item.type, item.uid),
                },
                {
                  name: "Löschen",
                  icon: "delete",
                  action: () => deleteRecord(item.type, item.uid),
                },
              ]}
            />
          </Grid>
        ))
      );
    } else {
      setRecordItems([]);
    }
  }, [records, recordTypes]);

  return (
    <>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "fill",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {/* <Grid item>
              <MDAvatar src="" alt="profile-image" size="xl" shadow="sm" />
            </Grid> */}
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Aktensystem
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Hier findest du die Behörden- und Unternehmensakten.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={3} md={3} lg={3} sx={{ ml: "auto" }}>
              <Autocomplete
                multiple
                autoSelect
                freeSolo
                defaultValue={[]}
                options={[]}
                renderInput={(params) => (
                  <MDBox>
                    <MDInput {...params} label="Name/Filter" variant="standard" />
                  </MDBox>
                )}
                value={recordFilter}
                onChange={(event, newValue) => {
                  setRecordFilter(newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Autocomplete
                multiple
                autoSelect
                defaultValue={[]}
                options={RECORD_ACRONYMS}
                renderInput={(params) => (
                  <MDBox>
                    <MDInput {...params} label="Aktentyp..." variant="standard" />
                  </MDBox>
                )}
                value={recordTypes}
                onChange={(event, newValue) => {
                  setRecordTypes(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.short} (${option.full.charAt(0).toUpperCase() + option.full.slice(1)})`
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <MDButton
                variant="gradient"
                color="secondary"
                onClick={() => {
                  createRecord();
                }}
                fullWidth
                disabled={recordTypes.length !== 1}
              >
                Neue Akte anlegen
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      {/* <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Some of Our Awesome Projects</MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text">
              This is the paragraph where you can write more details about your projects. Keep you
              user engaged by providing meaningful information.
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
          <MDButton variant="gradient" color="info">
            <Icon>add</Icon>&nbsp; Add New
          </MDButton>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        {recordItems}
        <AccessRightsModal
          ref={accessModalRef}
          accessors={recordAccessors}
          addAccessor={addRecordAccessorHere}
          updateAccessor={updateRecordAccessorHere}
          removeAccessor={removeRecordAccessorHere}
          submitChanges={submitRecordAccessorChanges}
          undoChanges={undoRecordAccessorChanges}
        />
      </Grid>
    </>
  );
}

export default RecordsExplorer;
