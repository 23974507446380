/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// React
import { useImperativeHandle, useState, forwardRef, useEffect, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Redux
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";
// import store from "app/store";
// import { setRecordUid, setRecordType } from "features/records/recordSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// eslint-disable-next-line no-unused-vars
const StreamingModal = forwardRef(({ resourceTypeLocal, resourceIdentifierLocal }, ref) => {
  const [modal, setModal] = useState(false);
  const resourceType = useSelector((state) => state.streaming.resourceType);
  const resourceIdentifier = useSelector((state) => state.streaming.resourceIdentifier);
  const resourcePageNumber = useSelector((state) => state.streaming.resourcePageNumber);
  const resourceMaxPages = useSelector((state) => state.streaming.resourceMaxPages);
  const device = useSelector((state) => state.streaming.device);
  const deviceList = useSelector((state) => state.streaming.deviceList);

  useImperativeHandle(ref, () => ({
    open() {
      setModal(true);
    },
  }));

  const startStream = () => {
    sendWebSocket({
      type: "updateStream",
      action: "startStream",
      resourceType: resourceTypeLocal,
      resourceIdentifier: resourceIdentifierLocal,
      device,
    });
  };

  const stopStream = () => {
    sendWebSocket({
      type: "updateStream",
      action: "stopStream",
      resourceType: resourceTypeLocal,
      resourceIdentifier: resourceIdentifierLocal,
      device,
    });
  };

  const previousPage = () => {
    sendWebSocket({
      type: "updateStream",
      action: "previousPage",
      resourceType: resourceTypeLocal,
      resourceIdentifier: resourceIdentifierLocal,
      device,
    });
  };

  const nextPage = () => {
    sendWebSocket({
      type: "updateStream",
      action: "nextPage",
      resourceType: resourceTypeLocal,
      resourceIdentifier: resourceIdentifierLocal,
      device,
    });
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <Modal
      open={modal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox p={3}>
          <MDTypography id="modal-modal-title" variant="h5">
            {resourceType && resourceIdentifier && device
              ? `Du streamst derzeit auf ${device}.`
              : `Möchtest du auf dem Bildschirm neben dir streamen?`}
          </MDTypography>
          <MDTypography id="modal-modal-description" variant="h6">
            {resourceType && resourceIdentifier && device
              ? `Seite ${resourcePageNumber}/${resourceMaxPages}`
              : ``}
          </MDTypography>
          {resourceType && resourceIdentifier && device ? (
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <MDButton variant="gradient" color="primary" fullWidth onClick={previousPage}>
                    Vorherige Seite
                  </MDButton>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MDButton variant="gradient" color="secondary" fullWidth onClick={nextPage}>
                    Nächste Seite
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          ) : (
            <MDBox mt={2} />
          )}
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={startStream}
                  disabled={resourceType && resourceIdentifier && device}
                >
                  Starten
                </MDButton>
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={stopStream}
                  disabled={!(resourceType && resourceIdentifier && device)}
                >
                  Beenden
                </MDButton>
              </Grid>
              <Grid item xs={4} sm={4}>
                <MDButton variant="gradient" color="secondary" fullWidth onClick={closeModal}>
                  Schließen
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  );
}, []);

export default StreamingModal;
