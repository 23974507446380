/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples

// Redux
import { useSelector } from "react-redux";
import store from "app/store";
import {
  setRecordSignatures,
  setRecordAccessors,
  addRecordAccessor,
  updateRecordAccessor,
  removeRecordAccessor,
  setRecordResources,
  setRecordContent,
} from "features/records/recordSlice";

// EditProduct page components
import Members from "layouts/records/editor/components/Members";
import SignaturePad from "layouts/records/editor/components/SignaturePad";

// Record components
import PatientRecord from "layouts/records/editor/components/Records/PatientRecord";
import MedicalRecord from "layouts/records/editor/components/Records/MedicalRecord";
import CriminalRecord from "layouts/records/editor/components/Records/CriminalRecord";
import FineRecord from "layouts/records/editor/components/Records/FineRecord";
import WarningRecord from "layouts/records/editor/components/Records/WarningRecord";
import CorruptionRecord from "layouts/records/editor/components/Records/CorruptionRecord";
import ContractRecord from "layouts/records/editor/components/Records/ContractRecord";
import CitizenRecord from "layouts/records/editor/components/Records/CitizenRecord";
import AutopsyRecord from "layouts/records/editor/components/Records/AutopsyRecord";
import { useEffect, useState, useRef } from "react";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";
import AccessRightsModal from "components/nhl/AccessRightsModal";

function RecordViewer() {
  const accessToken = useSelector((state) => state.account.token);
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordAccessors = useSelector((state) => state.records.recordAccessors);
  const recordEditors = useSelector((state) => state.records.recordEditors);

  const [recordLabel, setRecordLabel] = useState("");
  const [recordSubtitle, setRecordSubtitle] = useState("");
  const [recordComponent, setRecordComponent] = useState("");

  const selectedProfile = useSelector((state) => state.account.selectedProfile);
  const profiles = useSelector((state) => state.account.profiles);
  const [canSign, setCanSign] = useState(false);

  const accessModalRef = useRef();

  const openAccess = () => {
    // store.dispatch(setRecordAccessors(null));
    sendWebSocket({
      type: "getRecordAccessors",
      recordType,
      recordUid,
    });

    accessModalRef.current.open();
  };

  const saveRecord = () => {
    sendWebSocket({
      type: "saveRecord",
      recordType,
      recordUid,
    });
  };

  const addRecordAccessorHere = (mail, permission) => {
    console.log("addRecordAccessorHere(2)", mail, permission);
    store.dispatch(addRecordAccessor({ mail, permission }));
  };

  const updateRecordAccessorHere = (mail, permission) => {
    console.log("updateRecordAccessorHere (2)", mail, permission);
    store.dispatch(updateRecordAccessor({ mail, permission }));
  };

  const removeRecordAccessorHere = (mail) => {
    console.log("removeRecordAccessorHere (2)", mail);
    store.dispatch(removeRecordAccessor({ mail }));
  };

  const submitRecordAccessorChanges = () => {
    console.log("submitRecordAccessorChanges (2)");
    sendWebSocket({
      type: "setRecordAccessors",
      recordType,
      recordUid,
      recordAccessors,
    });
  };

  const undoRecordAccessorChanges = () => {
    console.log("undoRecordAccessorChanges (2)");
    sendWebSocket({
      type: "getRecordAccessors",
      recordType,
      recordUid,
    });
  };

  const checkCanSign = (selectedProfileTmp, profilesTmp, recordAccessorsTmp) => {
    if (selectedProfileTmp !== null && profilesTmp !== null && recordAccessorsTmp !== null) {
      const profileEntry = profilesTmp[selectedProfileTmp];
      const found = recordAccessorsTmp.find((x) => x.mail === profileEntry.mail);

      if (found) {
        if (found.permission === "sign" || found.permission === "edit") {
          setCanSign(true);
        } else {
          setCanSign(false);
        }
      } else {
        setCanSign(false);
      }
    }
  };

  useEffect(() => {
    checkCanSign(selectedProfile, profiles, recordAccessors);
  }, [selectedProfile, profiles, recordAccessors]);

  useEffect(() => {
    let label;
    let subtitle;
    let component;

    switch (recordType) {
      case "medical":
        label = `Krankenakte: ${recordUid}`;
        subtitle = `Dies ist eine Krankenakte.`;
        component = <MedicalRecord />;
        break;
      case "patient":
        label = `Patientenakte: ${recordUid}`;
        subtitle = `Dies ist eine Patientenakte.`;
        component = <PatientRecord />;
        break;
      case "criminal":
        label = `Strafakte: ${recordUid}`;
        subtitle = `Dies ist eine Strafakte.`;
        component = <CriminalRecord />;
        break;
      case "fine":
        label = `Bußgeldakte: ${recordUid}`;
        subtitle = `Dies ist eine Bußgeldakte.`;
        component = <FineRecord />;
        break;
      case "warning":
        label = `Verwarnungsakte: ${recordUid}`;
        subtitle = `Dies ist eine Verwarnungsakte.`;
        component = <WarningRecord />;
        break;
      case "corruption":
        label = `Korruptionsakte: ${recordUid}`;
        subtitle = `Dies ist eine Korruptionsakte.`;
        component = <CorruptionRecord />;
        break;
      case "contract":
        label = `Vertrag: ${recordUid}`;
        subtitle = `Dies ist ein Vertrag.`;
        component = <ContractRecord />;
        break;
      case "citizen":
        label = `Bürgerakte: ${recordUid}`;
        subtitle = `Dies ist ein Vertrag.`;
        component = <CitizenRecord />;
        break;
      case "autopsy":
        label = `Obduktionsakte: ${recordUid}`;
        subtitle = `Dies ist eine Obduktionsakte.`;
        component = <AutopsyRecord />;
        break;
      default:
        label = `${recordType}: ${recordUid}`;
        subtitle = `Dies ist eine ${recordType}-Akte`;
        break;
    }

    setRecordLabel(label);
    setRecordSubtitle(subtitle);
    setRecordComponent(component);

    if (recordUid) {
      store.dispatch(setRecordContent(null));
      store.dispatch(setRecordSignatures(null));
      store.dispatch(setRecordResources([]));
      store.dispatch(setRecordAccessors(null));

      sendWebSocket({
        type: "openRecord",
        recordType,
        recordUid,
      });

      sendWebSocket({
        type: "getRecordAccessors",
        recordType,
        recordUid,
      });

      // checkCanSign(selectedProfile, profiles, recordAccessors);

      return () => {
        sendWebSocket({
          type: "closeRecord",
          recordType,
          recordUid,
        });
        // eslint-disable-next-line no-console
        console.log("closeRecord fired");
      };
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [recordUid, recordType]);

  return (
    <MDBox my={3}>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={4} lg={4}>
            <MDTypography variant="h4" fontWeight="medium">
              {recordLabel}
            </MDTypography>
            <MDBox mt={1} mb={2}>
              <MDTypography variant="body2" color="text">
                {recordSubtitle}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8} lg={8}>
            <Grid container display="flex" justifyContent="flex-end" alignItems="flex-end">
              <MDBox>
                <Members members={recordEditors} />
              </MDBox>
              <MDBox mr={1}>
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Änderungen speichern und externe Akten-Inhalte laden"
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    tooltip="Test"
                    onClick={() => saveRecord()}
                  >
                    Speichern
                  </MDButton>
                </Tooltip>
              </MDBox>
              <MDBox>
                <Tooltip TransitionComponent={Zoom} title="Zugriffsrechte bearbeiten">
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    tooltip="Test"
                    onClick={() => openAccess()}
                  >
                    Zugriffsrechte
                  </MDButton>
                </Tooltip>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        {recordComponent}
      </Grid>
      <MDBox mt={3}>
        <SignaturePad disabled={!canSign} />
      </MDBox>
      <AccessRightsModal
        ref={accessModalRef}
        accessors={recordAccessors}
        addAccessor={addRecordAccessorHere}
        updateAccessor={updateRecordAccessorHere}
        removeAccessor={removeRecordAccessorHere}
        submitChanges={submitRecordAccessorChanges}
        undoChanges={undoRecordAccessorChanges}
      />
    </MDBox>
  );
}

export default RecordViewer;
