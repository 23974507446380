/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket, getRichSocket } from "app/websocket";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Richtext editor
import ReactQuill from "react-quill";
import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = getRichSocket();
// const connection = new Sharedb.Connection(socket);

function Patient() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);
  const informationQuill = useRef(null);
  const [patientIdFocused, setPatientIdFocused] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [contacts, setContacts] = useState([]);
  const [bloodType, setBloodType] = useState("");

  const updatePatientIdField = () => {
    const newContent = {};
    newContent.patientId = patientId;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    if (recordContent) {
      if (recordContent.patientId) {
        setPatientId(recordContent.patientId);
      }

      if (recordContent.mountedContent) {
        const patient = recordContent.mountedContent.find((x) => x.type === "patient");
        if (patient) {
          if (patient.patientFirstName) {
            setFirstName(patient.patientFirstName);
          }
          if (patient.patientLastName) {
            setLastName(patient.patientLastName);
          }
          if (patient.patientBirthday) {
            setBirthday(patient.patientBirthday);
          }
          if (patient.patientInformation) {
            informationQuill.current.getEditor().setContents(patient.patientInformation);
          }
          if (patient.patientBloodType) {
            setBloodType(patient.patientBloodType);
          }
          if (patient.patientContacts) {
            setContacts(patient.patientContacts);
          }
        } else {
          setFirstName("");
          setLastName("");
          setBirthday("");
          informationQuill.current.getEditor().setContents({ insert: "" });
          setBloodType("");
          setContacts([]);
        }
      } else {
        setFirstName("");
        setLastName("");
        setBirthday("");
        informationQuill.current.getEditor().setContents({ insert: "" });
        setBloodType("");
        setContacts([]);
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Patientendaten</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="ID"
                  fullWidth
                  value={patientId}
                  onChange={(event) => {
                    setPatientId(event.target.value);
                  }}
                  onFocus={() => {
                    setPatientIdFocused(true);
                  }}
                  onBlur={() => {
                    if (patientIdFocused) {
                      updatePatientIdField();
                    }
                    setPatientIdFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDBox mb={2}>
                <MDInput variant="standard" label="Vorname" fullWidth value={firstName} disabled />
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDBox mb={2}>
                <MDInput variant="standard" label="Name" fullWidth value={lastName} disabled />
              </MDBox>
            </Grid>
            <Grid item xs={2} sm={2}>
              <MDBox mb={2}>
                <MDInput
                  variant="standard"
                  label="Geburtstag"
                  fullWidth
                  value={birthday}
                  disabled
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={8} sm={8}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Allgemeine Gesundheitsinformationen&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (Unverträglichkeiten, Allergien)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={informationQuill} readOnly />
              </MDEditorRoot>
            </Grid>
            <Grid item xs={4} sm={4}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Blutgruppe
                </MDTypography>
              </MDBox>
              <Autocomplete
                freeSolo
                autoSelect
                value={bloodType}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
                disabled
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Patient;
