// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";

// React
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Redux
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// WebSocket
import { sendWebSocket } from "app/websocket.ts";
import store from "app/store";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// eslint-disable-next-line no-unused-vars
export function DocumentPreview() {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [url, setUrl] = useState("");
  const [searchParams] = useSearchParams();

  const onDocumentLoadSuccess = (doc) => {
    setNumPages(doc.numPages);
  };

  useEffect(() => {
    setPageNumber(Number(searchParams.get("page")));

    const urlTmp = `${process.env.REACT_APP_NHL_BACKEND_EXPRESS}/pdf/${searchParams.get(
      "document"
    )}`;
    console.log(urlTmp, url);
    if (urlTmp !== url) {
      setUrl(urlTmp);
    }
  }, [searchParams]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "98vh" }}
    >
      <Grid item>
        <Document
          file={url}
          onLoadSuccess={(doc) => {
            onDocumentLoadSuccess(doc);
          }}
          loading={<CircularProgress size="8rem" />}
        >
          <Page
            pageNumber={pageNumber}
            scale={2.385}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </Grid>
    </Grid>
  );
}

export default DocumentPreview;
