/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
// const connection = new Sharedb.Connection(socket);

function Infringement() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const reportQuill = useRef(null);
  const commentsQuill = useRef(null);

  const [infringements, setInfringements] = useState([]);
  const [witnesses, setWitnesses] = useState([]);
  const [penalties, setPenalties] = useState([]);

  let socket;
  let con;
  let reportDoc;
  const reportEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    reportDoc.submitOp(delta, {
      source: reportQuill.current.getEditor(),
    });
  };
  const reportDocOnOp = (op, source) => {
    if (source === reportQuill.current.getEditor()) return;
    reportQuill.current.getEditor().updateContents(op);
  };
  let commentsDoc;
  const commentsEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    commentsDoc.submitOp(delta, {
      source: commentsQuill.current.getEditor(),
    });
  };
  const commentsDocOnOp = (op, source) => {
    if (source === commentsQuill.current.getEditor()) return;
    commentsQuill.current.getEditor().updateContents(op);
  };

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    console.log("useEffect on recordUid change");

    // Get richtext/quill data
    if (reportDoc) {
      reportQuill.current.getEditor().off("text-change", reportEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      reportDoc.off("op", reportDocOnOp);
      // doc.removeAllListeners();
      reportDoc.unsubscribe();
      reportDoc.destroy();
    }
    if (commentsDoc) {
      commentsQuill.current.getEditor().off("text-change", commentsEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      commentsDoc.off("op", commentsDocOnOp);
      // doc.removeAllListeners();
      commentsDoc.unsubscribe();
      commentsDoc.destroy();
    }
    if (con) {
      // con.close();
    }
    if (socket) {
      socket.close();
    }

    socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
    con = new Sharedb.Connection(socket);

    // Get richtext/quill data for remarks doc

    reportDoc = con.get("documents", `record.warning.${recordUid}.report`);

    reportDoc.on("error", (err) => {
      console.error(err);
    });

    reportDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!reportDoc.type) {
        console.error(`Document doesn't exist yet: record.warning.${recordUid}.report`);
        return;
      }

      console.log("data", reportDoc.data);

      reportQuill.current.getEditor().setContents(reportDoc.data);

      reportQuill.current.getEditor().on("text-change", reportEditorOnTextChange);

      reportDoc.on("op", reportDocOnOp);
    });

    // Get richtext/quill data for autopsy doc

    commentsDoc = con.get("documents", `record.criminal.${recordUid}.comments`);

    commentsDoc.on("error", (err) => {
      console.error(err);
    });

    commentsDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!commentsDoc.type) {
        console.error(`Document doesn't exist yet: record.criminal.${recordUid}.comments`);
        return;
      }

      console.log("data", commentsDoc.data);

      commentsQuill.current.getEditor().setContents(commentsDoc.data);

      commentsQuill.current.getEditor().on("text-change", commentsEditorOnTextChange);

      commentsDoc.on("op", commentsDocOnOp);
    });

    return () => {
      // console.log("Closing connection");
      // connection.close();
    };
  }, [recordUid]);

  useEffect(() => {
    if (recordContent) {
      if (recordContent.infringements) {
        setInfringements(recordContent.infringements);
      }
      if (recordContent.witnesses) {
        setWitnesses(recordContent.witnesses);
      }
      if (recordContent.penalties) {
        setPenalties(recordContent.penalties);
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Ordnungswidrigkeiten</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Größte Ordnungswidrigkeit
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                value={infringements}
                onChange={(event, newValue) => {
                  updateField("infringements", newValue);
                }}
                options={["Raserei", "Totschlag", "Mord"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Zeugen
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                value={witnesses}
                onChange={(event, newValue) => {
                  updateField("witnesses", newValue);
                }}
                options={[]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Bericht&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={reportQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Anmerkungen&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={commentsQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Sanktionen
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                value={penalties}
                onChange={(event, newValue) => {
                  updateField("penalties", newValue);
                }}
                options={[]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Infringement;
