import { ReadyState } from "react-use-websocket";
import {
  setResourceDevice,
  setResourceDeviceList,
  setResourceIdentifier,
  setResourceMaxPages,
  setResourcePageNumber,
  setResourceType,
} from "features/streaming/streamingSlice";
import {
  setMailDrafts,
  setMailEditors,
  setMailInbox,
  setMailNavigate,
  setMailOutbox,
} from "features/mail/mailSlice";
import store from "./store";
import {
  setProfiles,
  setVerified,
  setRecentlys,
  setNavigate,
} from "../features/account/accountSlice";
import {
  setDocumentAccessors,
  setDocumentExt,
  setDocumentId,
  setDocuments,
} from "../features/cloud/cloudSlice";
import {
  setRecords,
  setRecordEditors,
  setRecordContent,
  setRecordSignatures,
  setRecordAccessors,
  setRecordUid,
  setRecordType,
  setRecordImagepool,
  setRecordResources,
  addRecordResources,
  setRecordTreatmentpool,
} from "../features/records/recordSlice";

/* eslint-disable no-console */
const WS_URL = process.env.REACT_APP_NHL_BACKEND_SOCKET;

const WS_RICH_URL = process.env.REACT_APP_NHL_RICHTEXT_SOCKET;

let ws: WebSocket;

let wsRich: WebSocket;

function sleep(milliseconds: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const openWebSocket = () => {
  if (ws) {
    ws.close();
  }
  if (wsRich) {
    wsRich.close();
  }

  ws = new WebSocket(`${WS_URL}`);
  wsRich = new WebSocket(`${WS_RICH_URL}`);

  ws.onopen = (e) => {
    console.log("WebSocket connection established.", e);

    console.log("Sending token: ", store.getState().account.token);

    ws.send(
      JSON.stringify({
        type: "token",
        token: store.getState().account.token,
      })
    );
  };

  ws.onmessage = (event) => {
    try {
      console.log("WebSocket message received...", event);
      const data = JSON.parse(event.data);

      console.log("WebSocket message received.", data);

      if (data.type === "verified") {
        store.dispatch(setVerified(true));

        ws.send(
          JSON.stringify({
            type: "getProfiles",
          })
        );
        console.log(
          "WebSocket sent: ",
          JSON.stringify({
            type: "getProfiles",
          })
        );
      }

      if (data.type === "profiles") {
        store.dispatch(setProfiles(data.profiles));
      }

      if (data.type === "recentlys") {
        store.dispatch(setRecentlys(data.recentlys));
      }

      if (data.type === "records") {
        store.dispatch(setRecords(data.records));
      }

      if (data.type === "setRecord") {
        store.dispatch(setRecordUid(data.recordUid));
        store.dispatch(setRecordType(data.recordType));
        store.dispatch(setNavigate("/records/editor"));
      }

      if (data.type === "recordAccessors") {
        store.dispatch(setRecordAccessors(data.recordAccessors));
      }

      if (data.type === "recordEditors") {
        store.dispatch(setRecordEditors(data.recordEditors));
      }

      if (data.type === "recordContent") {
        store.dispatch(setRecordContent(data.recordContent));
      }

      if (data.type === "recordSignatures") {
        store.dispatch(setRecordSignatures(data.recordSignatures));
      }

      if (data.type === "recordImagepool") {
        store.dispatch(setRecordImagepool(data.recordImagepool));
      }

      if (data.type === "recordResources") {
        store.dispatch(addRecordResources(data.recordResources));
      }

      if (data.type === "recordTreatmentpool") {
        store.dispatch(setRecordTreatmentpool(data.recordTreatmentpool));
      }

      if (data.type === "documents") {
        store.dispatch(setDocuments(data.documents));
      }

      if (data.type === "setDocument") {
        store.dispatch(setDocumentId(data.documentId));
        store.dispatch(setDocumentExt(data.documentExt));
        store.dispatch(setNavigate("/cloud/editor"));
      }

      if (data.type === "documentAccessors") {
        store.dispatch(setDocumentAccessors(data.documentAccessors));
      }

      if (data.type === "streamingData") {
        store.dispatch(setResourceType(data.resourceType));
        store.dispatch(setResourceIdentifier(data.resourceIdentifier));
        store.dispatch(setResourcePageNumber(data.resourcePageNumber));
        store.dispatch(setResourceMaxPages(data.resourceMaxPages));
        store.dispatch(setResourceDevice(data.device));
        store.dispatch(setResourceDeviceList(data.deviceList));
      }

      if (data.type === "mails") {
        store.dispatch(setMailInbox(data.mailInbox));
        store.dispatch(setMailOutbox(data.mailOutbox));
        store.dispatch(setMailDrafts(data.mailDrafts));
        if (data.mailNavigate) {
          store.dispatch(setMailNavigate(data.mailNavigate));
        }
      }

      if (data.type === "mailEditors") {
        store.dispatch(setMailEditors(data.mailEditors));
      }
    } catch (error) {
      console.error(error);
    }
  };

  ws.onclose = (event) => {
    console.log("WebSocket connection closed.", event);
  };
};

export const closeWebSocket = () => {
  if (ws) {
    ws.close();
    ws = null;
  }

  if (wsRich) {
    wsRich.close();
    wsRich = null;
  }
};

export const sendWebSocket = async (data: any) => {
  if (!ws) {
    openWebSocket();
  }

  while (ws.readyState !== ReadyState.OPEN) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
  }

  const filledData = {
    ...data,
    profile: store.getState().account.profiles[store.getState().account.selectedProfile],
    personal: store.getState().account.profiles[store.getState().account.personalProfile],
  };

  ws.send(JSON.stringify(filledData));
  console.log("WebSocket sent: ", JSON.stringify(filledData));
};

export const getRichSocket = () => {
  if (!wsRich) {
    openWebSocket();
  }

  return wsRich;
};
