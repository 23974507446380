/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDButton from "components/MDButton";

// React
import { useEffect, useState } from "react";

// Image
// import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";

// const team = [team1, team2, team3, team4, team5];
const team = [team4, team2];

function Members({ members }) {
  const [avatars, setAvatars] = useState(null);

  const avatarStyles = {
    border: ({ borders: { borderWidth }, palette: { white } }) =>
      `${borderWidth[2]} solid ${white.main}`,
    cursor: "pointer",
    position: "relative",
    ml: -1.5,

    "&:hover, &:focus": {
      zIndex: "10",
    },
  };

  useEffect(() => {
    if (members) {
      setAvatars(
        members.map((item, index) => (
          <Tooltip key={item.token} TransitionComponent={Zoom} title={item.name}>
            <MDAvatar
              src={
                /* team[Math.ceil(Math.random() * (team.length - 1))] */ team[
                  index < team.length ? index : team.length - 1
                ]
              }
              alt={item.name}
              size="sm"
              sx={avatarStyles}
            />
          </Tooltip>
        ))
      );
    } else {
      setAvatars([]);
    }
  }, [members]);

  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox mt={0} pr={2}>
        <MDBox lineHeight={0}>
          <MDTypography variant="caption" color="secondary">
            Mitbearbeiter:
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex">{avatars}</MDBox>
    </MDBox>
  );
}

// Setting default values for the props of MDTypography
Members.defaultProps = {
  members: [],
};

// Typechecking props for the MDTypography
Members.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
      uid: PropTypes.string,
    })
  ),
};

export default Members;
