/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
// import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
// import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// React
import { useEffect, useRef, useState } from "react";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// Redux
import { useSelector } from "react-redux";

function Appointments() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);

  const [dateFocused, setDateFocused] = useState(false);
  const [timeFocused, setTimeFocused] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [reasons, setReasons] = useState([]);
  const [medics, setMedics] = useState([]);

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  useEffect(() => {
    if (recordContent) {
      if (recordContent.appointmentDate) {
        setDate(recordContent.appointmentDate);
      }
      if (recordContent.appointmentTime) {
        setTime(recordContent.appointmentTime);
      }
      if (recordContent.appointmentReasons) {
        setReasons(recordContent.appointmentReasons);
      }

      if (recordContent.appointmentMedics) {
        setMedics(recordContent.appointmentMedics);
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Nächster Termin</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="Datum"
                  fullWidth
                  value={date}
                  onChange={(event) => {
                    setDate(event.target.value);
                  }}
                  onFocus={() => {
                    setDateFocused(true);
                  }}
                  onBlur={() => {
                    if (dateFocused) {
                      updateField("appointmentDate", date);
                    }
                    setDateFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  variant="standard"
                  label="Uhrzeit"
                  fullWidth
                  value={time}
                  onChange={(event) => {
                    setTime(event.target.value);
                  }}
                  onFocus={() => {
                    setTimeFocused(true);
                  }}
                  onBlur={() => {
                    if (timeFocused) {
                      updateField("appointmentTime", time);
                    }
                    setTimeFocused(false);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Grund
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                defaultValue={[]}
                value={reasons}
                onChange={(event, newValue) => {
                  updateField("appointmentReasons", newValue);
                }}
                options={["Nachuntersuchung", "Fäden ziehen", "Wundkontrolle"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Personal
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                defaultValue={[]}
                value={medics}
                onChange={(event, newValue) => {
                  updateField("appointmentMedics", newValue);
                }}
                options={["LSED-01", "LSED-02", "LSED-03"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Appointments;
