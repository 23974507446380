/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const streamingSlice = createSlice({
  name: "streaming",
  initialState: {
    resourceType: null,
    resourceIdentifier: null,
    resourcePageNumber: null,
    resourceMaxPages: null,
    device: null,
    deviceList: null,
  },
  reducers: {
    setResourceType: (state, action) => {
      state.resourceType = action.payload;
    },
    setResourceIdentifier: (state, action) => {
      state.resourceIdentifier = action.payload;
    },
    setResourcePageNumber: (state, action) => {
      state.resourcePageNumber = action.payload;
    },
    setResourceMaxPages: (state, action) => {
      state.resourceMaxPages = action.payload;
    },
    setResourceDevice: (state, action) => {
      state.device = action.payload;
    },
    setResourceDeviceList: (state, action) => {
      state.deviceList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setResourceType,
  setResourceIdentifier,
  setResourcePageNumber,
  setResourceMaxPages,
  setResourceDevice,
  setResourceDeviceList,
} = streamingSlice.actions;

export default streamingSlice.reducer;
