// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
// import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DirectoryCard from "examples/Cards/CloudCards/DirectoryCard";

// React
import React, { useEffect, useState, useRef } from "react";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Redux
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// WebSocket
import { sendWebSocket } from "app/websocket.ts";
import store from "app/store";
import {
  addDocumentAccessor,
  removeDocumentAccessor,
  setDocumentId,
  updateDocumentAccessor,
} from "features/cloud/cloudSlice";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

// Images
import bgImage1 from "assets/images/bg-home-1.jpg";
import bgImage2 from "assets/images/bg-home-2.jpg";
import bgImage3 from "assets/images/bg-home-3.jpg";
import bgImage4 from "assets/images/bg-home-4.jpg";
import bgImage5 from "assets/images/bg-home-5.jpg";
import brandWhite from "assets/images/logo-cloudstorm.png";
import brandDark from "assets/images/logo-cloudstorm-dark.png";
import { setNavigate } from "features/account/accountSlice";

import AccessRightsModal from "components/nhl/AccessRightsModal";
import StreamingModal from "components/nhl/StreamingModal";
// import bgImage from "assets/images/bg-pricing.jpg";

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];
const bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

const DOCUMENT_ACRONYMS = [
  /* { full: "Verzeichnis", short: "directory", ext: "*" }, */
  { full: "Textdokument", short: "docs", ext: "odt" },
  { full: "Tabelle", short: "sheets", ext: "ods" },
  { full: "Präsentation", short: "slides", ext: "odp" },
];

// eslint-disable-next-line no-unused-vars
export function DocumentExplorer() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const documents = useSelector((state) => state.cloud.documents);
  const documentAccessors = useSelector((state) => state.cloud.documentAccessors);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedProfile = useSelector((state) => state.account.selectedProfile);
  const [files, setFiles] = useState(null);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const navigateTo = useSelector((state) => state.account.navigate);

  const [documentExtensions, setDocumentExtensions] = useState([]);
  const [documentFilter, setDocumentFilter] = useState([]);

  const accessModalRef = useRef();
  const [accessId, setAccessId] = useState(null);

  const streamingModalRef = useRef();
  const [streamingId, setStreamingId] = useState(null);

  const openDocument = (documentId) => {
    console.log("Open Document", documentId);
    store.dispatch(setDocumentId(documentId));
    navigate({ pathname: "/cloud/editor", search: window.location.search });
  };

  const createDocument = () => {
    sendWebSocket({
      type: "createDocument",
      documentExt: documentExtensions[0].ext,
      documentName: documentFilter[0],
    });
  };

  const deleteDocument = (documentId) => {
    sendWebSocket({
      type: "deleteDocument",
      documentId,
    });
  };

  const openAccess = (documentId) => {
    // store.dispatch(setRecordAccessors(null));
    sendWebSocket({
      type: "getDocumentAccessors",
      documentId,
    });

    setAccessId(documentId);

    accessModalRef.current.open();
  };

  const openStreaming = (documentId) => {
    // store.dispatch(setRecordAccessors(null));
    sendWebSocket({
      type: "getStreamingData",
    });

    setStreamingId(documentId);

    streamingModalRef.current.open();
  };

  const addDocumentAccessorHere = (mail, permission) => {
    store.dispatch(addDocumentAccessor({ mail, permission }));
  };

  const updateDocumentAccessorHere = (mail, permission) => {
    store.dispatch(updateDocumentAccessor({ mail, permission }));
  };

  const removeDocumentAccessorHere = (mail) => {
    store.dispatch(removeDocumentAccessor({ mail }));
  };

  const submitDocumentAccessorChanges = () => {
    sendWebSocket({
      type: "setDocumentAccessors",
      documentId: accessId,
      documentAccessors,
    });
  };

  const undoRecordAccessorChanges = () => {
    sendWebSocket({
      type: "getDocumentAccessors",
      documentId: accessId,
    });
  };

  // const fileItems = ["Item 6", "Item 7", "Item 8", "Item 9", "Item 10"];
  useEffect(() => {
    sendWebSocket({
      type: "getDocuments",
    });
  }, [selectedProfile]);

  useEffect(() => {
    const target = navigateTo;
    store.dispatch(setNavigate(null));
    navigate({ pathname: target, search: window.location.search });
  }, [navigateTo]);

  useEffect(() => {
    console.log("documents changed...");

    if (documents) {
      setFiles(
        documents.map((item) => (
          <Grid item xs={2} sm={2} key={item.id}>
            <DirectoryCard
              title={{ text: `${item.filename}.${item.extension}` }}
              icon={{
                color: item.type === "directory" ? "dark" : "info",
                component: item.type === "directory" ? "folder" : "description",
              }}
              direction="left"
              contextItems={[
                {
                  name: "Öffnen",
                  icon: item.type === "directory" ? "folder_open" : "file_open",
                  action:
                    item.type === "directory"
                      ? () => openDocument(item.id)
                      : () => openDocument(item.id),
                },
                {
                  name: "Zugriffsrechte",
                  icon: "key",
                  action:
                    item.type === "directory"
                      ? () => openAccess(item.id, "directory")
                      : () => openAccess(item.id, "file"),
                },
                {
                  name: "Streaming",
                  icon: "cast",
                  action: () => openStreaming(item.id),
                },
                {
                  name: "Löschen",
                  icon: "delete",
                  action:
                    item.type === "directory"
                      ? () => deleteDocument(item.id)
                      : () => deleteDocument(item.id),
                },
              ]}
            />
          </Grid>
        ))
      );
    } else {
      setFiles([]);
    }
  }, [documents]);

  return (
    <>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "fill",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {/* <Grid item>
        <MDAvatar src="" alt="profile-image" size="xl" shadow="sm" />
      </Grid> */}
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Cloud
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Hier findest du deine persönlichen, behördlichen und beruflichen Dokumente.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={3} md={3} lg={3} sx={{ ml: "auto" }}>
              <Autocomplete
                multiple
                autoSelect
                freeSolo
                defaultValue={[]}
                options={[]}
                renderInput={(params) => (
                  <MDBox>
                    <MDInput {...params} label="Name/Filter" variant="standard" />
                  </MDBox>
                )}
                value={documentFilter}
                onChange={(event, newValue) => {
                  setDocumentFilter(newValue);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Autocomplete
                multiple
                autoSelect
                defaultValue={[]}
                options={DOCUMENT_ACRONYMS}
                renderInput={(params) => (
                  <MDBox>
                    <MDInput {...params} label="Dokumenttyp..." variant="standard" />
                  </MDBox>
                )}
                value={documentExtensions}
                onChange={(event, newValue) => {
                  setDocumentExtensions(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.short} (${option.full.charAt(0).toUpperCase() + option.full.slice(1)})`
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <MDButton
                variant="gradient"
                color="secondary"
                tooltip="Test"
                onClick={() => {
                  createDocument();
                }}
                fullWidth
                disabled={documentExtensions.length !== 1 || documentFilter.length !== 1}
              >
                Neues Dokument anlegen
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Grid container spacing={2}>
        {files}
        <AccessRightsModal
          ref={accessModalRef}
          accessors={documentAccessors}
          addAccessor={addDocumentAccessorHere}
          updateAccessor={updateDocumentAccessorHere}
          removeAccessor={removeDocumentAccessorHere}
          submitChanges={submitDocumentAccessorChanges}
          undoChanges={undoRecordAccessorChanges}
        />
        <StreamingModal
          ref={streamingModalRef}
          resourceTypeLocal="document"
          resourceIdentifierLocal={streamingId}
        />
      </Grid>
    </>
  );
}

export default DocumentExplorer;
