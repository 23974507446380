/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples

// EditProduct page components
import Patient from "layouts/records/editor/components/Records/MedicalRecord/patient";
import Mission from "layouts/records/editor/components/Records/MedicalRecord/mission";
import Appointments from "layouts/records/editor/components/Records/MedicalRecord/appointments";
import Treatment from "layouts/records/editor/components/Records/MedicalRecord/treatment";

function MedicalRecord() {
  return (
    <>
      <Grid item xs={12} lg={4}>
        <Mission />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Patient />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Appointments />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Treatment />
      </Grid>
    </>
  );
}

export default MedicalRecord;
