/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const cloudSlice = createSlice({
  name: "cloud",
  initialState: {
    documents: [],
    documentId: null,
    documentExt: null,
    documentAccessors: [{ mail: "test", permission: "sign" }],
  },
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setDocumentId: (state, action) => {
      state.documentId = action.payload;
    },
    setDocumentExt: (state, action) => {
      state.documentExt = action.payload;
    },
    setDocumentAccessors: (state, action) => {
      state.documentAccessors = action.payload;
    },
    addDocumentAccessor: (state, action) => {
      state.documentAccessors.push({
        mail: action.payload.mail,
        permission: action.payload.permission,
      });
    },
    updateDocumentAccessor: (state, action) => {
      console.log("updateDocumentAccessor", action.payload);

      const index = state.documentAccessors.findIndex((x) => x.mail === action.payload.mail);
      console.log("index", index);
      if (index >= 0) {
        state.documentAccessors[index] = {
          ...state.documentAccessors[index],
          permission: action.payload.permission,
        };
      }
    },
    removeDocumentAccessor: (state, action) => {
      const index = state.documentAccessors.findIndex((x) => x.mail === action.payload.mail);
      if (index >= 0) {
        state.documentAccessors.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDocuments,
  setDocumentId,
  setDocumentExt,
  setDocumentAccessors,
  addDocumentAccessor,
  updateDocumentAccessor,
  removeDocumentAccessor,
} = cloudSlice.actions;

export default cloudSlice.reducer;
