/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket, getRichSocket } from "app/websocket";

// NHL imports
import ImportResourcesModal from "components/nhl/ImportResourcesModal";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = getRichSocket();
// const connection = new Sharedb.Connection(socket);

function Treatment() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);
  const recordTreatmentpool = useSelector((state) => state.records.recordTreatmentpool);
  const importModalRef = useRef();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const detailedInjuriesQuill = useRef(null);
  const detailedTreatmentQuill = useRef(null);

  const [injuries, setInjuries] = useState([]);
  const [medicine, setMedicine] = useState([]);

  let socket;
  let con;
  let injuriesDoc;
  const injuriesEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    injuriesDoc.submitOp(delta, {
      source: detailedInjuriesQuill.current.getEditor(),
    });
  };
  const injuriesDocOnOp = (op, source) => {
    if (source === detailedInjuriesQuill.current.getEditor()) return;
    detailedInjuriesQuill.current.getEditor().updateContents(op);
  };
  let treatmentDoc;
  const treatmentEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    treatmentDoc.submitOp(delta, {
      source: detailedTreatmentQuill.current.getEditor(),
    });
  };
  const treatmentDocOnOp = (op, source) => {
    if (source === detailedTreatmentQuill.current.getEditor()) return;
    detailedTreatmentQuill.current.getEditor().updateContents(op);
  };

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  const openImport = () => {
    sendWebSocket({
      type: "getRecordTreatmentpool",
      recordType,
      recordUid,
    });

    importModalRef.current.open();
  };

  const importResourcesHere = (resources) => {
    // eslint-disable-next-line no-console
    console.log("importResourcesHere (2)", resources);

    let newDetailedInjuries = "";
    resources.forEach((resource) => {
      if (resource.injuries) {
        resource.injuries.forEach((injury) => {
          newDetailedInjuries += `${injury}\n`;
        });
      }
    });
    const rangeDetailedInjuriesQuill = detailedInjuriesQuill.current.getEditor().getSelection();
    const positionDetailedInjuriesQuill = rangeDetailedInjuriesQuill
      ? rangeDetailedInjuriesQuill.index
      : 0;
    detailedInjuriesQuill.current
      .getEditor()
      .insertText(positionDetailedInjuriesQuill, newDetailedInjuries, "import");

    let newDetailedTreatment = "";
    resources.forEach((resource) => {
      if (resource.treatment) {
        resource.treatment.forEach((treatment) => {
          newDetailedTreatment += `${treatment}\n`;
        });
      }
    });
    const rangeDetailedTreatmentQuill = detailedTreatmentQuill.current.getEditor().getSelection();
    const positionDetailedTreatmentQuill = rangeDetailedTreatmentQuill
      ? rangeDetailedTreatmentQuill.index
      : 0;
    detailedTreatmentQuill.current
      .getEditor()
      .insertText(positionDetailedTreatmentQuill, newDetailedTreatment, "import");

    const newMedicine = [...recordContent.medicine];
    resources.forEach((resource) => {
      if (resource.medicine) {
        resource.medicine.forEach((medicineHere) => {
          newMedicine.push(medicineHere);
        });
      }
    });

    updateField("medicine", newMedicine);
  };

  useEffect(() => {
    console.log("useEffect on recordUid change");

    // Get richtext/quill data
    if (injuriesDoc) {
      detailedInjuriesQuill.current.getEditor().off("text-change", injuriesEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      injuriesDoc.off("op", injuriesDocOnOp);
      // doc.removeAllListeners();
      injuriesDoc.unsubscribe();
      injuriesDoc.destroy();
    }
    if (treatmentDoc) {
      detailedTreatmentQuill.current.getEditor().off("text-change", treatmentEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      treatmentDoc.off("op", treatmentDocOnOp);
      // doc.removeAllListeners();
      treatmentDoc.unsubscribe();
      treatmentDoc.destroy();
    }
    if (con) {
      // con.close();
    }
    if (socket) {
      socket.close();
    }

    socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
    con = new Sharedb.Connection(socket);

    // Get richtext/quill data for remarks doc

    injuriesDoc = con.get("documents", `record.medical.${recordUid}.detailedInjuries`);

    injuriesDoc.on("error", (err) => {
      console.error(err);
    });

    injuriesDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!injuriesDoc.type) {
        console.error(`Document doesn't exist yet: record.medical.${recordUid}.detailedInjuries`);
        return;
      }

      console.log("data", injuriesDoc.data);

      detailedInjuriesQuill.current.getEditor().setContents(injuriesDoc.data);

      detailedInjuriesQuill.current.getEditor().on("text-change", injuriesEditorOnTextChange);

      injuriesDoc.on("op", injuriesDocOnOp);
    });

    // Get richtext/quill data for autopsy doc

    treatmentDoc = con.get("documents", `record.medical.${recordUid}.detailedTreatment`);

    treatmentDoc.on("error", (err) => {
      console.error(err);
    });

    treatmentDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!treatmentDoc.type) {
        console.error(`Document doesn't exist yet: record.medical.${recordUid}.detailedTreatment`);
        return;
      }

      console.log("data", treatmentDoc.data);

      detailedTreatmentQuill.current.getEditor().setContents(treatmentDoc.data);

      detailedTreatmentQuill.current.getEditor().on("text-change", treatmentEditorOnTextChange);

      treatmentDoc.on("op", treatmentDocOnOp);
    });

    return () => {
      // console.log("Closing connection");
      // connection.close();
    };
  }, [recordUid]);

  useEffect(() => {
    if (recordContent) {
      if (recordContent.injuries) {
        setInjuries(recordContent.injuries);
      }
      if (recordContent.medicine) {
        setMedicine(recordContent.medicine);
      }
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Diagnose</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Größte Verletzung
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                value={injuries}
                onChange={(event, newValue) => {
                  updateField("injuries", newValue);
                }}
                options={["Fraktur", "Schürfwunde", "Hämatom", "Prellung"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={1} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Medikamente
                </MDTypography>
              </MDBox>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                defaultValue={[]}
                value={medicine}
                onChange={(event, newValue) => {
                  updateField("medicine", newValue);
                }}
                options={["Ibuprofen", "Paracetamol"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Verletzungen&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={detailedInjuriesQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Behandlung&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={detailedTreatmentQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <MDButton variant="gradient" color="secondary" onClick={() => openImport()} fullWidth>
            Behandlungsnotizen importieren
          </MDButton>
          <ImportResourcesModal
            ref={importModalRef}
            resources={recordTreatmentpool}
            importResources={importResourcesHere}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Treatment;
