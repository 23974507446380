/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket, getRichSocket } from "app/websocket";

// NHL imports
import ImportResourcesModal from "components/nhl/ImportResourcesModal";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = getRichSocket();
// const connection = new Sharedb.Connection(socket);

function Results() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);
  const recordTreatmentpool = useSelector((state) => state.records.recordTreatmentpool);
  const importModalRef = useRef();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const remarksQuill = useRef(null);
  const autopsyQuill = useRef(null);

  let socket;
  let con;
  let remarksDoc;
  const remarksEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    remarksDoc.submitOp(delta, {
      source: remarksQuill.current.getEditor(),
    });
  };
  const remarksDocOnOp = (op, source) => {
    if (source === remarksQuill.current.getEditor()) return;
    remarksQuill.current.getEditor().updateContents(op);
  };
  let autopsyDoc;
  const autopsyEditorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    autopsyDoc.submitOp(delta, {
      source: autopsyQuill.current.getEditor(),
    });
  };
  const autopsyDocOnOp = (op, source) => {
    if (source === autopsyQuill.current.getEditor()) return;
    autopsyQuill.current.getEditor().updateContents(op);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("useEffect on recordUid change");

    // Get richtext/quill data
    if (remarksDoc) {
      remarksQuill.current.getEditor().off("text-change", remarksEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      remarksDoc.off("op", remarksDocOnOp);
      // doc.removeAllListeners();
      remarksDoc.unsubscribe();
      remarksDoc.destroy();
    }
    if (autopsyDoc) {
      autopsyQuill.current.getEditor().off("text-change", autopsyEditorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      autopsyDoc.off("op", autopsyDocOnOp);
      // doc.removeAllListeners();
      autopsyDoc.unsubscribe();
      autopsyDoc.destroy();
    }
    if (con) {
      // con.close();
    }
    if (socket) {
      socket.close();
    }

    socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
    con = new Sharedb.Connection(socket);

    // Get richtext/quill data for remarks doc

    remarksDoc = con.get("documents", `record.autopsy.${recordUid}.remarks`);

    remarksDoc.on("error", (err) => {
      console.error(err);
    });

    remarksDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!remarksDoc.type) {
        console.error(`Document doesn't exist yet: record.autopsy.${recordUid}.remarks`);
        return;
      }

      console.log("data", remarksDoc.data);

      remarksQuill.current.getEditor().setContents(remarksDoc.data);

      remarksQuill.current.getEditor().on("text-change", remarksEditorOnTextChange);

      remarksDoc.on("op", remarksDocOnOp);
    });

    // Get richtext/quill data for autopsy doc

    autopsyDoc = con.get("documents", `record.autopsy.${recordUid}.autopsy`);

    autopsyDoc.on("error", (err) => {
      console.error(err);
    });

    autopsyDoc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!autopsyDoc.type) {
        console.error(`Document doesn't exist yet: record.autopsy.${recordUid}.autopsy`);
        return;
      }

      console.log("data", autopsyDoc.data);

      autopsyQuill.current.getEditor().setContents(autopsyDoc.data);

      autopsyQuill.current.getEditor().on("text-change", autopsyEditorOnTextChange);

      autopsyDoc.on("op", autopsyDocOnOp);
    });

    return () => {
      // console.log("Closing connection");
      // connection.close();
    };
  }, [recordUid]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Obduktionsbericht</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Vermerke&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={remarksQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Obduktion&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={autopsyQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Results;
