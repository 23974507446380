/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    id: null,
    token: null,
    verified: false,
    profiles: [
      { type: "personal", mail: "robin.spencer@mail.ls", name: "Robin Spencer" },
      { type: "faction", mail: "lsrd", name: "LS Rescue Dept." },
      { type: "faction", mail: "lspd", name: "LS Police Dept." },
      { type: "faction", mail: "wn", name: "Weazel News" },
      { type: "faction", mail: "noxcars", name: "NoxCars" },
    ],
    selectedProfile: 0, // the currently selected profile of type "personal" or "faction"
    personalProfile: 0, // the last selected profile of type "personal"
    recentlys: [],
    navigate: null,
  },
  reducers: {
    setId: (state, action) => {
      state.id += action.id;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setVerified: (state, action) => {
      state.verified = action.payload;
    },
    setProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    setSelectedProfile: (state, action) => {
      state.selectedProfile = action.payload;
    },
    setPersonalProfile: (state, action) => {
      state.personalProfile = action.payload;
    },
    setRecentlys: (state, action) => {
      state.recentlys = action.payload;
    },
    setNavigate: (state, action) => {
      state.navigate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setId,
  setToken,
  setVerified,
  setProfiles,
  setSelectedProfile,
  setPersonalProfile,
  setRecentlys,
  setNavigate,
} = accountSlice.actions;

export default accountSlice.reducer;
