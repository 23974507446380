/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Anaytics dashboard components
// eslint-disable-next-line import/no-named-as-default

// Data

// Images
import bgImage1 from "assets/images/bg-home-1.jpg";
import bgImage2 from "assets/images/bg-home-2.jpg";
import bgImage3 from "assets/images/bg-home-3.jpg";
import bgImage4 from "assets/images/bg-home-4.jpg";
import bgImage5 from "assets/images/bg-home-5.jpg";
import brandWhite from "assets/images/logo-cloudstorm.png";
import brandDark from "assets/images/logo-cloudstorm-dark.png";
// import bgImage from "assets/images/bg-pricing.jpg";

const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];
const bgImage = bgImages[Math.floor(Math.random() * bgImages.length)];

function Home() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        position="relative"
        minHeight="75vh"
        height="75vh"
        borderRadius="xl"
        m={2}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgImage})`,
          backgroundSize: "fit",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ position: "relative", py: 22, textAlign: "center" }}
          style={{ filter: "drop-shadow(2px 2px 10px #202020)" }}
        >
          <Grid item xs={11} lg={5}>
            <MDBox component="img" src={brandWhite} alt="Brand" width="10rem" />
            <MDBox mb={1}>
              <MDTypography variant="h2" color="white" fontWeight="bold">
                There is no place like Cloudstorm.
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="white" fontWeight="light">
                Everything you can dream of is everything you will get.
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Home;
