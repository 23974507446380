/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// Richtext editor
import ReactQuill, { Quill } from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
const connection = new Sharedb.Connection(socket);
let paragraphsDocTmp;

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }],
    [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "size",
  "align",
  "blockquote",
  "color",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function Paragraphs() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const paragraphsQuill = useRef(null);

  const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  };

  const editorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user") return;
    paragraphsDocTmp.submitOp(delta, {
      source: paragraphsQuill.current.getEditor(),
    });
    console.log("editorOnTextChange", paragraphsQuill.current.getEditor() !== undefined);
  };

  const docOnOp = (op, source) => {
    console.log("docOnOp", paragraphsQuill.current.getEditor() !== undefined);
    if (source === paragraphsQuill.current.getEditor()) return;
    paragraphsQuill.current.getEditor().updateContents(op);
  };

  useEffect(() => {
    if (paragraphsQuill != null) {
      paragraphsQuill.current.getEditor().off("text-change", editorOnTextChange);
      paragraphsQuill.current.getEditor().on("text-change", editorOnTextChange);
    }

    return () => {
      if (paragraphsQuill != null && paragraphsQuill.current?.getEditor() != null) {
        paragraphsQuill.current.getEditor().off("text-change", editorOnTextChange);
        console.log("paragraphsQuill off event");
      }
    };
  }, [paragraphsQuill]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("useEffect on recordUid change");

    // Get richtext/quill data
    paragraphsDocTmp = connection.get("documents", `record.contract.${recordUid}.paragraphs`);

    paragraphsDocTmp.subscribe((err) => {
      if (err) throw err;
      paragraphsQuill.current.getEditor().setContents(paragraphsDocTmp.data);

      paragraphsDocTmp.on("op", docOnOp);
    });

    return () => {
      console.log("paragraph unmounted");
      // connection.close();
      if (paragraphsDocTmp) {
        paragraphsDocTmp.off("op", docOnOp);
        paragraphsDocTmp.unsubscribe();
        paragraphsDocTmp.destroy();

        console.log("doc destroyed");
      }
    };
  }, [recordUid]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Vertragliche Bestimmungen</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block" />
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill
                  theme="snow"
                  ref={paragraphsQuill}
                  modules={modules}
                  formats={formats}
                />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Paragraphs;
