/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const mailSlice = createSlice({
  name: "mail",
  initialState: {
    mailInbox: [
      {
        id: "bla1",
        ts: 123,
        from: ["mail@lsed.gov"],
        to: ["mail@lspd.gov", "robin.spencer@mail.ls"],
        subject: "Inbox Test",
        content: [{ insert: "Heyy" }], // Richtext
      },
      {
        id: "bla2",
        ts: 123,
        from: ["mail@lspd.gov"],
        to: ["mail@lspd.gov", "robin.spencer@mail.ls"],
        subject: "Inbox Test",
        content: [{ insert: "Heyho" }], // Richtext
      },
    ],
    mailOutbox: [
      {
        id: "bla1",
        ts: 123,
        from: ["mail@lsed.gov"],
        to: ["mail@lspd.gov", "robin.spencer@mail.ls"],
        subject: "Sent Test",
        content: [{ insert: "" }], // Richtext
      },
    ],
    mailDrafts: [
      {
        id: "bla1",
        ts: 123,
        from: ["mail@lsed.gov"],
        to: ["mail@lspd.gov", "robin.spencer@mail.ls"],
        subject: "Test",
        content: [{ insert: "" }], // Richtext
      },
    ],
    mailEditors: [{ token: "xyz", name: "Testo Steron" }],
    mailNavigate: null,
  },
  reducers: {
    setMailInbox: (state, action) => {
      state.mailInbox = action.payload;
    },
    setMailOutbox: (state, action) => {
      state.mailOutbox = action.payload;
    },
    setMailDrafts: (state, action) => {
      state.mailDrafts = action.payload;
    },
    setMailEditors: (state, action) => {
      state.mailEditors = action.payload;
    },
    setMailNavigate: (state, action) => {
      state.mailNavigate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMailInbox, setMailOutbox, setMailDrafts, setMailEditors, setMailNavigate } =
  mailSlice.actions;

export default mailSlice.reducer;
