/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function DirectoryCard({ bgColor, title, percentage, icon, direction, contextItems }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);

  const dropdownItems = contextItems.map((item) => {
    const linkComponent = {
      component: MuiLink,
      href: item.href,
      target: "_blank",
      rel: "noreferrer",
    };

    const routeComponent = {
      component: Link,
      to: item.route,
    };

    return (
      <MDTypography
        key={item.name}
        {...(item.route ? routeComponent : linkComponent)}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        variant="button"
        textTransform="capitalize"
        minWidth={item.description ? "14rem" : "12rem"}
        color={item.description ? "dark" : "text"}
        fontWeight={item.description ? "bold" : "regular"}
        py={item.description ? 1 : 0.625}
        px={2}
        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
          borderRadius: borderRadius.md,
          cursor: "pointer",
          transition: "all 300ms linear",

          "&:hover": {
            backgroundColor: grey[200],
            color: dark.main,

            "& *": {
              color: dark.main,
            },
          },
        })}
        onMouseEnter={({ currentTarget }) => {
          if (item.dropdown) {
            setNestedDropdown(currentTarget);
            setNestedDropdownEl(currentTarget);
            setNestedDropdownName(item.name);
          }
        }}
        onMouseLeave={() => {
          if (item.dropdown) {
            setNestedDropdown(null);
          }
        }}
        onClick={() => {
          if (item.action) {
            item.action();
          }
        }}
      >
        {item.description ? (
          <MDBox display="flex" py={0.25} fontSize="1rem" color="text">
            {typeof item.icon === "string" ? (
              <Icon color="inherit">{item.icon}</Icon>
            ) : (
              <MDBox color="inherit">{item.icon}</MDBox>
            )}
            <MDBox pl={1} lineHeight={0}>
              <MDTypography
                variant="button"
                display="block"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {item.name}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                {item.description}
              </MDTypography>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox display="flex" alignItems="center" color="text">
            <Icon sx={{ mr: 1 }}>{item.icon}</Icon>
            {item.name}
          </MDBox>
        )}
        {item.collapse && (
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle", mr: -0.5 }}>
            keyboard_arrow_right
          </Icon>
        )}
      </MDTypography>
    );
  });

  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="bottom-start"
      transition
      style={{ zIndex: 999 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName("");
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <MDBox borderRadius="lg">
            <MDTypography variant="h1" color="white">
              <Icon ref={setArrowRef} sx={{ mt: -3 }}>
                arrow_drop_up
              </Icon>
            </MDTypography>
            <MDBox shadow="lg" borderRadius="lg" p={1.625} mt={1}>
              {dropdownItems}
            </MDBox>
          </MDBox>
        </Grow>
      )}
    </Popper>
  );

  return (
    <Card
      sx={{ overflow: "hidden" }}
      onMouseEnter={({ currentTarget }) => {
        setDropdown(currentTarget);
        setDropdownEl(currentTarget);
        setDropdownName("Test");
      }}
      onMouseLeave={() => setDropdown(null)}
    >
      <MDBox
        bgColor={bgColor}
        variant="gradient"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
        onClick={contextItems[0] && contextItems[0].action ? contextItems[0].action : null}
      >
        <MDBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item xs={4}>
                <MDBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="4rem"
                  height="4rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="medium" color="inherit">
                    {icon.component}
                  </Icon>
                </MDBox>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <MDBox
                ml={direction === "left" ? 2 : 0}
                lineHeight={1}
                textAlign={direction === "left" ? "right" : "left"}
              >
                <MDTypography
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </MDTypography>
                <MDTypography
                  variant="h5"
                  fontWeight="bold"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {/* {count}{" "} */}
                  <MDTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Grid>
            {direction === "right" ? (
              <Grid item xs={4}>
                <MDBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="4rem"
                  height="4rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="medium" color="inherit">
                    {icon.component}
                  </Icon>
                </MDBox>
              </Grid>
            ) : null}
          </Grid>
        </MDBox>
      </MDBox>
      {dropdownMenu}
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
DirectoryCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "light",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
  contextItems: [],
};

// Typechecking props for the MiniStatisticsCard
DirectoryCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
  contextItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
      route: PropTypes.string,
      icon: PropTypes.string,
      action: PropTypes.func,
    })
  ),
};

export default DirectoryCard;
