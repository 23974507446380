import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../features/account/accountSlice";
import counterReducer from "../features/counter/counterSlice";
import cloudReducer from "../features/cloud/cloudSlice";
import recordsReducer from "../features/records/recordSlice";
import streamingReducer from "../features/streaming/streamingSlice";
import mailReducer from "../features/mail/mailSlice";

export default configureStore({
  reducer: {
    account: accountReducer,
    counter: counterReducer,
    cloud: cloudReducer,
    records: recordsReducer,
    streaming: streamingReducer,
    mail: mailReducer,
  },
});
