/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @react-jvectormap components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Other Components
// import Frame from "react-frame-component";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";

// jQuery
import $ from "jquery";

import axios from "axios";

// axios
// import axios from "axios";

// Material Dashboard 2 PRO React examples

// Redux
import { useSelector } from "react-redux";

// eslint-disable-next-line no-unused-vars
export function DocumentViewer(props) {
  // eslint-disable-next-line no-unused-vars
  const [wopiUrl, setWopiUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const accessToken = useSelector((state) => state.account.token);
  const documentId = useSelector((state) => state.cloud.documentId);

  useEffect(() => {
    console.log("accessToken", accessToken);
    console.log("documentId", documentId);
    if (accessToken && documentId) {
      axios
        .get(`${process.env.REACT_APP_NHL_BACKEND_EXPRESS}/collaboraUrl`)
        .then((response) => {
          // handle success
          const wopiClientUrl = response.data.clientUrl;
          const wopiHostUrl = `${response.data.hostUrl}/wopi/files/${documentId}`;
          console.log(wopiClientUrl);
          const wopiUrlRaw = `${wopiClientUrl}WOPISrc=${wopiHostUrl}`;
          $("#collabora-submit-form").attr("action", wopiUrlRaw);
          $("#access-token").attr("value", accessToken);
          // $("#access-token-ttl").attr("value", Date.now() + 1000000);

          console.log("wopiUrlRaw:", wopiUrlRaw);

          setWopiUrl(wopiUrlRaw);

          $("#collabora-submit-form").submit();
        })
        .catch((error) => {
          // handle error
          console.log(`Request Failed: ${error}`);
          alert("Not possible to retrieve the complete Collabora Online url");
        })
        .finally(() => {
          // always executed
        });
    }
  }, [accessToken, documentId]);

  // const locationOrigin = window.location.origin;
  // const scheme = locationOrigin.startsWith("https") ? "https" : "http";

  /* const wopiClientHost = "http://192.168.2.121:9980";
    if (!wopiClientHost) {
      alert("No server address entered");
      return;
    }
    if (!wopiClientHost.startsWith("http")) {
      alert(
        "Warning! You have to specify the scheme protocol too (http|https) for the server address."
      );
      return;
    }
    if (!wopiClientHost.startsWith(`${scheme}://`)) {
      alert("Collabora Online server address scheme does not match the current page url scheme");
      return;
    } */

  // const wopiSrc = `${locationOrigin}/wopi/files/1`;
  // const wopiSrc = `http://192.168.2.121:3007/wopi/files/646802098e38e3db9f982d37`;
  // console.log("wopiSrc", wopiSrc);

  // props.sendWebsocketMessage({ type: "test" });

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            description
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          Dokument-Editor
        </MDTypography>
      </MDBox>
      <div style={{ display: "none" }}>
        <form
          action="#"
          encType="multipart/form-data"
          method="post"
          target="collabora-online-viewer"
          id="collabora-submit-form"
        >
          <input name="access_token" type="hidden" id="access-token" />
          <input name="access_token_ttl" type="hidden" id="access-token-ttl" />
          <input type="submit" value="" />
        </form>
      </div>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            {/* <Frame
            initialContent="<!DOCTYPE html><html><head></head><body><h1>i wont be changed</h1><div id='mountHere'></div></body></html>"
            mountTarget="#mountHere"
            src="https://platform.twitter.com/widgets/tweet_button.html"
            style={{ width: "90%", height: "90%" }}
          /> */}

            <iframe
              id="collabora-online-viewer"
              name="collabora-online-viewer"
              title="test"
              style={{
                flexGrow: 1,
                border: "none",
                margin: 0,
                padding: 0,
                width: "100%",
                height: "75vh",
              }}
              ancest="true"
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default DocumentViewer;
