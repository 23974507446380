// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import { useDispatch } from "react-redux";
import { openWebSocket } from "app/websocket.ts";
import { setToken } from "../features/account/accountSlice";
import store from "../app/store";

export default function Authenticator({ children }) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  // const dispatch = useDispatch();
  // const auth = useSelector((state) => state.counter.value);
  // eslint-disable-next-line no-console

  store.dispatch(setToken(token));

  openWebSocket();

  // return <div onLoad={() => dispatch(setToken(token))}>{children}</div>;
  return <div>{children}</div>;
}

Authenticator.propTypes = {
  children: PropTypes.node.isRequired,
};
