/* eslint-disable no-console */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import MDInput from "components/MDInput";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Redux
import { useSelector } from "react-redux";

// WebSocket
// eslint-disable-next-line import/no-unresolved
import { sendWebSocket } from "app/websocket";

// Richtext editor
import ReactQuill from "react-quill";
// import "quill/dist/quill.bubble.css";
import Sharedb from "sharedb/lib/client";
import richText from "rich-text";

// Registering the rich text type to make sharedb work
// with our quill editor
Sharedb.types.register(richText.type);

// Connecting to our socket server
// const socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
// const connection = new Sharedb.Connection(socket);

function Comments() {
  const recordUid = useSelector((state) => state.records.recordUid);
  const recordType = useSelector((state) => state.records.recordType);
  const recordContent = useSelector((state) => state.records.recordContent);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const informationQuill = useRef(null);

  let socket;
  let con;
  let doc;
  const editorOnTextChange = (delta, oldDelta, source) => {
    if (source !== "user" && source !== "import") return;
    doc.submitOp(delta, {
      source: informationQuill.current.getEditor(),
    });
  };
  const docOnOp = (op, source) => {
    if (source === informationQuill.current.getEditor()) return;
    informationQuill.current.getEditor().updateContents(op);
  };

  /* const updateField = (field, newValue) => {
    const newContent = {};
    newContent[field] = newValue;

    sendWebSocket({
      type: "updateRecord",
      recordType,
      recordUid,
      recordContent: newContent,
    });
  }; */

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("useEffect on recordUid change");

    if (doc) {
      informationQuill.current.getEditor().off("text-change", editorOnTextChange);
      // contentQuill.current.getEditor().removeAllListeners();
      doc.off("op", docOnOp);
      // doc.removeAllListeners();
      doc.unsubscribe();
      doc.destroy();
    }
    if (con) {
      // con.close();
    }
    if (socket) {
      socket.close();
    }

    socket = new WebSocket(process.env.REACT_APP_NHL_RICHTEXT_SOCKET);
    con = new Sharedb.Connection(socket);

    // Get richtext/quill data

    doc = con.get("documents", `record.citizen.${recordUid}.citizenInformation`);

    doc.on("error", (err) => {
      console.error(err);
    });

    doc.subscribe((err) => {
      console.log("doc subscribed");

      if (err) {
        console.error(`error:${err}`);
        return;
      }
      if (!doc.type) {
        console.error(`Document doesn't exist yet: record.citizen.${recordUid}.citizenInformation`);
        return;
      }

      console.log("data", doc.data);

      informationQuill.current.getEditor().setContents(doc.data);

      informationQuill.current.getEditor().on("text-change", editorOnTextChange);

      doc.on("op", docOnOp);
    });

    return () => {
      // console.log("Closing connection");
      // connection.close();
    };
  }, [recordUid]);

  useEffect(() => {
    if (recordContent) {
      /* if (recordContent.patientBloodType) {
        setBloodType(recordContent.patientBloodType);
      }

      if (recordContent.patientContacts) {
        setContacts(recordContent.patientContacts);
      } */
    }
  }, [recordContent]);

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informationen</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Allgemeine Informationen&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (Auffälligkeiten, Informantentätigkeit)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditorRoot ownerState={{ darkMode }}>
                <ReactQuill theme="snow" ref={informationQuill} />
              </MDEditorRoot>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Comments;
